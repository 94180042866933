<template>
    <div class="cascade_item">
        <div class="cascade_set_name">
            <el-input v-model="option.label" autocomplete="off" placeholder="请输入"></el-input>
            <div class="cascade_btn">
                <i class="iconfont guoran-tongyichicun-tianjiatongji" v-if="brotherAdd" @click="addNode('brother')"></i>
                <i class="iconfont guoran-tongyichicun-tianjiazibumen" v-if="childrenAdd" @click="addNode('children')"></i>
            </div>
        </div>
        <div class="cascade_set_value">
            <el-input v-model="option.value" autocomplete="off" placeholder="请输入"></el-input>
        </div>
        <div class="cascade_set_radio">
            <!-- :disabled="" -->
            <el-radio v-if="selectType == '单选'" :disabled="option.children && option.children.length != 0"
                v-model="option.checked" :label="true" @change="checkedChange"></el-radio>
            <el-checkbox v-if="selectType == '多选'" :disabled="option.children && option.children.length != 0"
                v-model="option.checked" @change="checkedChange"></el-checkbox>
        </div>
        <div class="cascade_set_del" @click="delClick">
            <i class="iconfont guoran-tongyichicun-16-09-shanchu2"></i>
        </div>
    </div>
</template>

<script>
export default {
    props: ["selectType", "option", "brotherAdd", "childrenAdd"],
    data () {
        return {
            radio: false,
        }
    },
    methods: {
        addNode (type) {
            this.$emit("addNode", type)
        },
        delClick () {
            this.$emit("delClick")
        },
        checkedChange () {
            console.log(this.option, 'this.option');
            if (this.selectType == '单选') {
                this.$emit('checkedChange', this.option.key)
            }
        }
    }
}
</script>

<style lang="less" scoped>
.cascade_item {
    width: 100%;
    height: 42px;
    display: flex;
    align-items: center;
    margin: 5px 0;
    cursor: pointer;
    position: relative;

    .el-input {
        width: 222px;
        margin: 0 5px;
    }

    .cascade_set_name {
        width: 232px;
        display: flex;
        align-items: center;

        .cascade_btn {
            display: none;
            align-items: center;
            color: #366Aff;
            cursor: pointer;

            i {
                margin: 0 5px;
            }
        }
    }

    .cascade_set_name:hover {
        .cascade_btn {
            display: flex;
        }
    }

    .cascade_set_value {
        width: 232px;
    }

    .cascade_set_radio {
        width: 60px;
        display: flex;
        align-items: center;
        justify-content: center;

        /deep/.el-radio {
            .el-radio__label {
                display: none;
            }
        }
    }

    .cascade_set_del {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: -32px;

        i {
            font-size: 16px;
            color: #366aff;
        }
    }
}

.cascade_item::after {
    content: "";
    width: 20px;
    height: 0;
    border-top: 2px dashed #366aff;
    position: absolute;
    left: 15px;
    top: 50%;
    transform: translateY(-50%);
}


.cascade_item::before {
    content: "";
    width: 0;
    height: 45px;
    border-left: 2px dashed #366aff;
    position: absolute;
    left: 15px;
    top: 0;
    // transform: translateY(-50%);
}</style>