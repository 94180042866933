<template>
  <div class="webhook_config">
    <div class="webhook_info">
      <div class="title">
        <i class="el-icon-arrow-down"
           @click="showInfo = !showInfo"
           :style="{ transform:!showInfo ? '' : 'rotateZ(180deg)' }"
        ></i>
        <span>基础信息</span>
      </div>
      <div class="info_container" :class="showInfo ? 'show_info' : 'hide_info'">
        <div class="webhook_name">
          <div class="col">名称</div>
          <el-input  placeholder="请输入名称" v-model="webhookDetail.name" :disabled="isDisabled" size="medium"></el-input>
        </div>
        <div class="webhook_desc">
          <div class="col">描述</div>
          <el-input  placeholder="请输入描述" v-model="webhookDetail.description" :disabled="isDisabled" size="medium"></el-input>
        </div>
      </div>
    </div>
    <div class="api_interface">
      <div class="title">
        <i class="el-icon-arrow-down"
           @click="showInterface = !showInterface"
           :style="{ transform:!showInterface ? '' : 'rotateZ(180deg)' }"
        ></i>
        <span>API接口</span>
      </div>
      <div class="api_container" :class="showInterface ? 'show_info' : 'hide_info'">
        <div class="connect_url">
          <el-input placeholder="请输入" v-model="webhookDetail.url" :disabled="isDisabled" size="medium">
            <el-select slot="prepend" v-model="webhookDetail.method" placeholder="请选择" :disabled="isDisabled" size="medium">
              <el-option
                  v-for="item in methodOptions"
                  :key="item.value"
                  :label="item.name"
                  :value="item.value">
              </el-option>
            </el-select>
          </el-input>
        </div>
        <el-button type="primary" size="mini" @click="sendRequest">
          <i class="iconfont guoran-lianjie" style="font-size: 12px"></i>
          连接
        </el-button>
      </div>
    </div>
    <div class="parameter_template">
      <div class="title">
        <i class="el-icon-arrow-down"
           @click="showParameterTemplate = !showParameterTemplate"
           :style="{ transform:!showParameterTemplate ? '' : 'rotateZ(180deg)' }"
        ></i>
        <span>参数模板</span>
      </div>
      <div class="parameter_json" :class="showParameterTemplate ? 'show_info' : 'hide_info'">
        <div class="tips" v-if="false">
          <i class="arsenal_icon arsenalshoucang1"></i>当入参较为复杂时，可配置参数模板，将参数json预填后可在参数配置处引用变量
        </div>
<!--        <json-editor-->
<!--            v-model="jsonTemplateData"-->
<!--            :options="options"-->
<!--            :height="300"-->
<!--            class="custom-json-editor"-->
<!--        ></json-editor>-->
        <el-input
            type="textarea"
            :autosize="{ minRows: 5, maxRows: 12}"
            placeholder="请输入"
            :disabled="isDisabled"
            v-model="jsonTemplateData">
        </el-input>
      </div>
    </div>
    <div class="request_parameters">
      <div class="title">
        <i class="el-icon-arrow-down"
           @click="showParameter = !showParameter"
           :style="{ transform:!showParameter ? '' : 'rotateZ(180deg)' }"
        ></i>
        <span>请求参数</span>
      </div>
      <div class="request_parameters_container" :class="showParameter ? 'show_info' : 'hide_info'">
        <div class="request_item">
          <el-tabs v-model="activeName">
            <el-tab-pane label="请求参数" name="first" >
              <div class="parameters_type">
                <el-radio-group :disabled="isDisabled" v-model="paramTypeData">
                  <el-radio label="none" >无</el-radio>
                  <el-radio label="form-data">form-data</el-radio>
                  <el-radio label="x-www-form-urlencoded">x-www-form-urlencoded</el-radio>
                  <el-radio label="raw">raw</el-radio>
                  <el-radio label="binary">binary</el-radio>
                </el-radio-group>
              </div>
              <div class="tips" v-if="false">
                <i class="arsenal_icon arsenalshoucang1"></i>
                {{tips}}
              </div>
              <div class="request_item_container">
                <div class="params params_title">
                  <div class="params-name">
                    参数名称
                    <div class="addParams" @click="addParams" :class="isDisabled ? 'disable-click':''">添加参数</div>
                  </div>
                  <div class="params-ismust">是否必需</div>
                  <div class="params-value">默认值</div>
                  <div class="params-delete">操作</div>
                </div>
                <div class="content" id="paramsContent">
                  <div class="params" v-for="(param,index) in paramsConf" v-bind:key="index">
                    <div class="params-name">
                      <el-input placeholder="请输入参数名称" v-model="param.key" :disabled="isDisabled" size="medium"></el-input>
                    </div>
                    <div class="params-ismust">
                      <el-checkbox v-model="param.required" :disabled="isDisabled"></el-checkbox>
                    </div>
                    <div class="params-value">
                      <el-input placeholder="请输入默认值" v-model="param.value" :disabled="isDisabled" size="medium"></el-input>
                    </div>
                    <div class="params-delete">
                      <i class="iconfont guoran-tongyichicun-16-09-shanchu2" :class="isDisabled ? 'disable-click':''" v-if="paramsConf.length>1" @click="deleteParams(index)"></i>
                    </div>
                  </div>
                </div>
              </div>
            </el-tab-pane>
            <el-tab-pane label="HTTPHeaders" name="third">
              <div class="headers">
                <div class="key">
                  KEY
                  <div class="addHeader" @click="addHeaders" :class="isDisabled ? 'disable-click':''">添加请求头</div>
                </div>
                <div class="value">VALUE</div>
                <div class="desc">DESCRIPTION</div>
              </div>
              <div class="headers" v-for="(header,index) in headersConf" v-bind:key="index">
                <div class="key"><el-input placeholder="请输入KEY" v-model="header.key" :disabled="isDisabled" size="medium"></el-input></div>
                <div class="value"><el-input placeholder="请输入VALUE" v-model="header.value" :disabled="isDisabled" size="medium"></el-input></div>
                <div class="desc"><el-input placeholder="请输入DESCRIPTION" v-model="header.desc" :disabled="isDisabled" size="medium"></el-input></div>

                <div class="operation">
                  <i class="iconfont guoran-tongyichicun-16-09-shanchu2" :class="isDisabled ? 'disable-click':''" v-if="headersConf.length>1" @click="deleteHeaders(index)" :disabled="isDisabled"></i>
                </div>
              </div>
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>
    </div>
    <div class="request_results">
      <div class="title">
        <i class="el-icon-arrow-down"
           @click="showResults = !showResults"
           :style="{ transform:!showResults ? '' : 'rotateZ(180deg)' }"
        ></i>
        <span>请求结果</span>
      </div>
      <div class="results_content" :class="showResults ? 'show_info' : 'hide_info'">
        <div class="response-content">
          <json-viewer
              :value="sendResponseData"
              :expand-depth=5
              copyable
              boxed
              sort>
          </json-viewer>
        </div>
        <div class="response-config">
          <div class="config_title">
            <div class="name">
              返回值名称
              <div class="add_response_config" @click="addResponseConfig" :class="isDisabled ? 'disable-click':''">添加返回值</div>
            </div>
            <div class="multiple">是否为多值</div>
            <div class="select">选择返回值</div>
            <div class="operation">操作</div>
          </div>
          <div class="config-content">
            <div class="content" v-for="(respConf,index) in responseConfigData.config" v-bind:key="index">
              <div class="name"><el-input placeholder="请输入返回值名称" v-model="respConf.name" :disabled="isDisabled" size="medium"></el-input></div>
              <div class="multiple"><el-checkbox v-model="respConf.multivalued" :disabled="isDisabled"></el-checkbox></div>
              <div class="select"><el-input placeholder="请输入选择返回值" v-model="respConf.value" :disabled="isDisabled" size="medium"></el-input></div>
              <div class="operation">
                <i class="iconfont guoran-tongyichicun-16-09-shanchu2" :class="isDisabled ? 'disable-click':''" v-if="responseConfigData.config.length>1" @click="deleteResponseConfig(index)" :disabled="isDisabled"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import JsonEditor from 'v-jsoneditor';
export default {
  name: "WebHookConfig",
  data(){
    return{
      webhookDetail:{},
      showInfo:true,
      showInterface:true,
      showParameter:true,
      showParameterTemplate:true,
      showResults:true,
      methodOptions:[
        {
          name:'GET',
          value:"GET"
        },
        {
          name:"POST",
          value:"POST"
        },
        {
          name:'PUT',
          value:"PUT"
        },
        {
          name:"DELETE",
          value:"DELETE"
        }
      ],
      activeName: 'first',
      paramTypeData:'none',
      sendResponseData:{},
      jsonTemplateData:"",
      responseConfigData:{},
      options: {
        mode: 'code', // 可以是 'tree', 'view', 'form', 'code', 'text'
        onEditable: (node) => {
          // 这里可以根据需要配置哪些节点是可编辑的
          return true;
        }
      },
      tips:"参数名称可使用{{变量名}}、{{变量名.子变量名}}、{{变量名[数组索引]}}的方式引用参数模板中的变量",
      jsonError:false
    }
  },
  components:{
    JsonEditor
  },
  props:{
    webhook:{
      type:Object,
      default(){
        return {}
      }
    },
    isDisabled:{
      type:Boolean,
      default:false
    },
    paramsConf:{
      type:Array,
      default(){
        return []
      }
    },
    headersConf:{
      type:Array,
      default(){
        return []
      }
    },
    sendResponse:{
      type:Object,
      default(){
        return {}
      }
    },
    responseConf:{
      type:Object,
      default(){
        return {}
      }
    },
    jsonTemplate:{
      type:String,
      default:""
    },
    paramType:{
      type:String,
      default(){
        return 'none'
      }
    }
  },
  methods:{
    sendRequest(){
      this.$emit('sendRequest')
    },
    addParams(){
      this.$emit('addParams')
    },
    addHeaders(){
      this.$emit('addHeaders')
    },
    deleteParams(index){
      this.$emit('deleteParams',index)
    },
    deleteHeaders(index){
      this.$emit('deleteHeaders',index)
    },
    addResponseConfig(){
      this.$emit('addResponseConfig')
    },
    deleteResponseConfig(index){
      this.$emit('deleteResponseConfig',index)
    },
  },
  mounted() {

  },
  watch:{
    sendResponse:{
      handler(){
        this.sendResponseData = this.sendResponse;
      },
      immediate:true
    },
    responseConf:{
      handler(){
        this.responseConfigData = this.responseConf;
      },
      immediate:true
    },
    isDisabled:{
      handler(){
        if (this.isDisabled){
          this.options.mode = 'view'
        } else {
          this.options.mode = 'code'
        }
      },
      immediate:true
    },
    jsonTemplate:{
      handler(){
        // this.jsonTemplateData = this.jsonTemplate;
      },
      immediate:true
    },
    paramType:{
      handler(){
        this.paramTypeData = this.paramType
      },
      immediate:true
    }
  }
};
</script>

<style scoped lang="less">
.webhook_config{
  padding: 12px;
  background-color: #FFFFFF;
  text-align: left;
  height: calc(100% - 12px);
  overflow-y: scroll;
  .title{
    display: flex;
    align-items: center;
    margin-bottom: 12px;
    i{
      margin-right: 8px;
    }
    .el-icon-arrow-down{
      width: 18px;
      height: 18px;
      background: #FFFFFF;
      border-radius: 5px;
      border: 1px solid #A1B9FF;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .webhook_info{
    transition: all 0.3s;
    .webhook_name{
      margin-bottom: 12px;
    }
    .col{
      padding-bottom: 8px;
      color: #616161;
      font-size: 12px;
    }
  }
  .hide_info{
    height: 0;
    overflow: hidden;
  }
  .api_interface{
    padding-top: 14px;
    .api_container{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      background-color: #ffffff;
      .connect_url{
        width: 90%;
        margin: 0px 10px 0 0;
        .el-input--suffix{
          width: 100px;
        }
        /deep/.el-input-group__prepend{
          width: 60px;
          flex: none;
        }
        /deep/.el-select .el-input.is-disabled .el-input__inner{
          background-color: #F5F7FA;
        }
        /deep/.el-input-group__prepend{
          background-color: #FFFFFF;
        }
      }
    }
  }
  .parameter_template{
    padding-top: 14px;
    .parameter_json{
      /deep/.jsoneditor-menu{
        display: none;
      }
      /deep/.jsoneditor-navigation-bar{
        display: none;
      }
    }
    /deep/.ace-jsoneditor{
      min-height: 250px;
    }
    /deep/.ace_content{
      background-color: #1D1F21;
      color: #C5C8C6;
    }
    /deep/.ace_gutter{
      background-color: #1D1F21;
      color: #C5C8C6;
    }
    /deep/.ace_gutter-active-line{
      background-color: #1D1F21;
    }
    /deep/.ace_active-line{
      background-color: #1D1F21;
    }
    /deep/.ace_cursor{
      border-color: #AEAFAD;
    }
    /deep/.ace_paren{
      color: #C5C8C6;
    }
    /deep/.ace_editor{
      background-color: #1D1F21;
    }
    /deep/.ace_bracket{
      color: #C5C8C6;
    }
    /deep/.ace_variable{
      color: #CC6666;
    }
  }
  .tips{
    background: #FFF8F4;
    border-radius: 5px;
    border: 1px solid #FFD9C5;
    padding: 10px;
    font-size: 12px;
    color: #FF9555;
    margin: 10px 0;
    i{
      padding-right: 6px;
      font-size: 12px;
    }
  }
  .request_parameters{
    padding-top: 14px;
    .request_parameters_container{
      .request_item{
        padding: 10px;
        background-color: #ffffff;
        /deep/.el-tabs__item{
          color: #A9B3C6;
          font-size: 12px;
        }
        /deep/.el-tabs__item.is-active{
          color: #366aff;
        }
        .el-input__inner{
          height: 32px;
          line-height: 32px;
        }
        .params_title{
          background: #F6F8FD;
          border-radius: 5px 5px 0px 0px;
          height: 45px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 0 10px;
        }
        .parameters_type{
          margin: 10px 0px;
          background: #F8F9FD;
          border-radius: 5px;
          padding: 8px 10px;
        }
        .request_item_container{
          background: #FBFCFD;
          border-radius: 5px;
          .content{
            padding: 10px;
          }
        }
        .params{
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          margin-top: 4px;
          .params-name{
            width: 40%;
            display: flex;
            align-items: center;
            font-size: 12px;
            .addParams{
              width: 71px;
              height: 26px;
              background: #366AFF;
              border-radius: 13px;
              display: flex;
              align-items: center;
              justify-content: center;
              margin-left: 10px;
              cursor: pointer;
              color: #FFFFFF;
              font-size: 12px;
            }
          }
          .params-ismust{
            width: 80px;
            flex: none;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 12px;
          }
          .params-value{
            width: 40%;
            font-size: 12px;
          }
          .params-entity{
            width: 20%;
            font-size: 12px;
          }
          .params-delete{
            width: 30px;
            flex: none;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 12px;
            i{
              color: #366aff;
              cursor: pointer;
            }
          }
        }
        .auth{
          display: flex;
          flex-direction: column;
          justify-content: center;
          text-align: center;
          margin: 20px 0 0 20px;
          .username{
            display: flex;
            flex-direction: row;
            align-items: center;
            width: 60%;
            span{
              width: 60px;
            }
          }
          .password{
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-top: 20px;
            width: 60%;
            span{
              width: 60px;
            }
          }
          .token{
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-top: 20px;
            width: 60%;
            span{
              width: 60px;
            }
          }
        }
        .headers{
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 5px;
          .key{
            width: 29%;
            display: flex;
            align-items: center;
            .addHeader{
              width: 71px;
              height: 26px;
              background: #366AFF;
              border-radius: 13px;
              display: flex;
              align-items: center;
              justify-content: center;
              color: #ffffff;
              font-size: 12px;
              margin-left: 10px;
              cursor: pointer;
            }
          }
          .value{
            width: 29%;
          }
          .desc{
            width: 35%;
          }
          .operation{
            width: 30px;
            flex: none;
            display: flex;
            align-items: center;
            justify-content: center;
            i{
              color: #366aff;
              cursor: pointer;
            }
          }
        }
        .body-content{
          .title{
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
          }
          .content{
            .form-data{
              margin-top: 20px;
            }
            .www-form{
              margin-top: 20px;
            }
          }
        }
      }
    }
  }
  .request_results{
    padding-top: 14px;
    .results_content{
      .response-content{
        text-align: left;
        margin-bottom: 30px;
        .boxed {
          overflow-y: scroll;
          height: 100% !important;
          background-color: #1D1F21!important;
        }
        /deep/.jv-object{
          color: #C5C8C6!important;
        }
        /deep/.jv-key{
          color: #CC6666!important;
        }
        /deep/.jv-string{
          color: green!important;
        }
        /deep/ .jv-number{
          color: #DE935F!important;
        }
        /deep/.jv-array{
          color: #C5C8C6!important;
        }
        .jv-code {
          padding: 0 20px;
          max-height: 100% !important;
        }
        .jv-more {
          display: none;
        }
      }
      .response-config{
        .config_title{
          background: #F6F8FD;
          border-radius: 5px 5px 0px 0px;
          height: 45px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 0 10px;
          font-size: 12px;
          .name{
            width: 40%;
            display: flex;
            align-items: center;
            font-size: 12px;
            .add_response_config{
              flex: none;
              width: 83px;
              height: 26px;
              background: #366AFF;
              border-radius: 13px;
              margin-left: 8px;
              display: flex;
              align-items: center;
              justify-content: center;
              font-size: 12px;
              color: #FFFFFF;
              cursor: pointer;
            }
          }
          .multiple{
            width: 80px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex: none;
            font-size: 12px;
          }
          .select{
            width: 40%;
            font-size: 12px;
          }
          .operation{
            width: 30px;
            flex: none;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 12px;
          }
        }
        .config-content{
          //width: 98%;
          height: 100%;
          overflow-x: auto;
          margin-bottom: 30px;
          background: #FBFCFD;
          border-bottom-left-radius: 5px;
          border-bottom-right-radius: 5px;
          padding: 10px;
          .content{
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            margin-top: 10px;
            height: 35px;
            .name{
              width: 40%;
            }
            .multiple{
              width: 80px;
              display: flex;
              align-items: center;
              justify-content: center;
              flex: none;
            }
            .select{
              width: 40%;
            }
            .operation{
              width: 30px;
              flex: none;
              display: flex;
              align-items: center;
              justify-content: center;
              i{
                color: #366aff;
                cursor: pointer;
              }
            }
          }
        }
      }
    }
  }

}

</style>