<template>
    <div class="cascade_option">
        <div class="cascade_set_num handles" @mouseenter="resortOptionSet" @mouseover="mouseUpClick">
            <i class="iconfont guoran-tongyichicun-16-10-paixu"></i>
        </div>
        <div class="option_parent">
            <cascadeItem :option="option" class="noneAfter" :selectType="selectType" @addNode="addNode" @delClick="delClick"
                @checkedChange="checkedChange" :brotherAdd="false" :childrenAdd="true"></cascadeItem>
        </div>
        <template v-if="option.children && option.children.length != 0">
            <div class="option_children" v-for="(p, pIndex) in option.children " :key="pIndex">
                <cascadeItem :option="p" :selectType="selectType" @addNode="(type) => { addNode(type, pIndex, undefined) }"
                    @delClick="() => { delClick(pIndex, undefined) }" @checkedChange="checkedChange"
                    :class="p.children && p.children.length != 0 ? 'has_children' : ''" :brotherAdd="true"
                    :childrenAdd="true">
                </cascadeItem>
                <template v-if="p.children && p.children.length != 0">
                    <div class="option_three" v-for="(pThree, pThreeIndex ) in  p.children " :key="pThreeIndex">
                        <div class="line" v-if="pIndex != option.children.length - 1"></div>
                        <cascadeItem :option="pThree" :selectType="selectType" :brotherAdd="true"
                            :childrenAdd="false" @addNode="(type) => { addNode(type, pIndex, pThreeIndex) }"
                            @checkedChange="checkedChange" @delClick="() => { delClick(pIndex, pThreeIndex) }">
                        </cascadeItem>
                    </div>
                </template>
            </div>
        </template>
    </div>
</template>

<script>
import cascadeItem from './cascadeItem.vue';
export default {
    props: ["option", "selectType"],
    components: {
        cascadeItem
    },
    data () {
        return {

        }
    },
    methods: {
        addNode (type, borIndex, threeIndex) {
            this.$emit("addNode", type, borIndex, threeIndex)
        },
        delClick (pIndex, pThreeIndex) {
            this.$emit("delClick", pIndex, pThreeIndex)
        },
        resortOptionSet () {
            this.$emit('resortOptionSet')
        },
        mouseUpClick () {
            this.$emit('mouseUpClick')
        },
        checkedChange (key) {
            this.$emit('checkedChange', key)
        }
    }
}
</script>

<style lang="less" scoped>
.cascade_option {
    margin: 5px 0;
    padding: 8px 0;
    background: #F6F8FD;
    border-radius: 5px;
    position: relative;

    .option_children {
        >.cascade_item {
            box-sizing: border-box;

            /deep/.cascade_set_name {
                width: 202px !important;

                .el-input {
                    width: 212px;
                    margin: 0 5px;
                }
            }

            padding-left: 30px;
        }



        .option_three {
            position: relative;

            >.cascade_item {
                box-sizing: border-box;
                padding-left: 60px;

                /deep/.cascade_set_name {
                    width: 172px !important;

                    .el-input {
                        width: 192px;
                        margin: 0 5px;
                    }
                }
            }

            .cascade_item::after {
                left: 44px;
            }

            .cascade_item::before {
                left: 44px;
            }

            .line {
                position: absolute;
                left: 15px;
                height: 100%;
                width: 0;
                border-left: 2px dashed #366aff;
            }
        }


    }

    .cascade_set_num {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: -20px;

        i {
            color: #366aff;
            font-size: 16px;
            cursor: pointer;
        }
    }
}

.has_children {
    .cascade_item::before {
        height: 22px !important;
    }
}

.cascade_option {
    .option_children:nth-last-child(1) {
        >.cascade_item::before {
            height: 22px;
        }
    }

    .option_three:nth-last-child(1) {
        >.cascade_item::before {
            height: 22px;
        }
    }
}

.noneAfter::after {
    content: none !important;
}

.noneAfter::before {
    content: none !important;
}
</style>