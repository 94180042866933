<template>
  <div>
    <div class="module-result-content-field">
      <div class="result-content-field">
        <el-table :data="fieldList" width="100%" height="calc(100vh - 180px)" class="tableClass"
          :header-cell-style="{ fontSize: '14px', fontWeight: '500' }"
          :row-style="{ fontSize: '14px', cursor: 'pointer', height: '72px', lineHeight: '72px', padding: '0!important' }"
          :row-class-name="getRowClass" :cell-style="{ padding: '0!important' }">
          <el-table-column type="index" :index="indexMethod" :label="$t('fieldManagement.number')" width="80"
            style="margin-left: 50px;" align="center" header-align="center">
          </el-table-column>
          <el-table-column :label="$t('fieldManagement.fieldName')" align="left" header-align="left" width="140px"
            style="text-align: left" :show-overflow-tooltip="true">
            <template scope="scope">
              <div class="fieldinfoName" style="overflow:hidden;white-space: nowrap;text-overflow: ellipsis">
                {{ scope.row.name }}
              </div>
            </template>
          </el-table-column>
          <el-table-column :label="$t('fieldManagement.fieldType')" align="left" header-align="left" width="150">
            <template scope="scope">
              <div>
                <span>{{ scope.row.type | typeLable }}</span>
              </div>
            </template>
          </el-table-column>

          <el-table-column :label="$t('fieldManagement.fieldDesc')" align="left" header-align="left"
            style="text-align: left" :show-overflow-tooltip="true">
            <template scope="scope">

              <div class="fieldinfoDes" v-if="scope.row.description !== ''">{{ scope.row.description }}</div>
              <div class="fieldinfoDes" v-if="scope.row.description === ''">--</div>

            </template>
          </el-table-column>
          <el-table-column v-if="RP_Visible('OPEN_FORM_FIELD_UPDATE') || RP_Visible('OPEN_FORM_FIELD_DELETE')"
            fixed="right" :label="$t('fieldManagement.operation')" width="100" align="center">
            <template scope="scope">
              <div class="fieldOpt" style="display: flex;justify-content: space-around">
                <span v-if="RP_Visible('OPEN_FORM_FIELD_UPDATE')" style="font-size: 20px;color: #366AFF;font-weight: 600"
                  @click="reviseField(scope.row.id, scope.row)"><i class="el-icon-edit"></i></span>
                <span v-if="RP_Visible('OPEN_FORM_FIELD_DELETE')" style="font-size: 20px;color: red;font-weight: 600"
                  @click="deleteOpenDialog(scope.row.id)"><i class="el-icon-delete"></i></span>
              </div>
            </template>
          </el-table-column>

        </el-table>
      </div>
    </div>
    <popup v-if="dialogFormVisible1" class="dialog_form" @closeEvent="dialogFormVisible1 = false"
      :close-on-click-modal="false">
      <div slot="popup-name" class="popup-name">{{ $t('fieldManagement.editField') }}</div>
      <div slot="popup-tip">{{ '编辑表单字段' }}</div>
      <div slot="popup-con">
        <el-form :model="form" :label-position="labelPosition" style="text-align: left">
          <el-form-item :label="$t('fieldManagement.fieldName')" :label-width="formLabelWidth">
            <el-input v-model="fieldName" autocomplete="off" :placeholder="$t('fieldManagement.fieldNamePlaceholder')"
              maxlength="8" show-word-limit></el-input>
          </el-form-item>
          <el-form-item :label="$t('fieldManagement.fieldDesc')" :label-width="formLabelWidth" style="text-align: left">
            <el-input v-model="fieldDescription" autocomplete="off"
              :placeholder="$t('fieldManagement.fieldDescPlaceholder')"></el-input>
          </el-form-item>
          <el-form-item :label="$t('fieldManagement.fieldType')" :label-width="formLabelWidth" style="text-align: left">
            <el-select v-model="fieldType.value" :placeholder="$t('fieldManagement.fieldTypePlaceholder')"
              @change="selectType" style="width: 100%">
              <el-option v-for="item in fieldTypes" :key=item.value :label="$t('fieldManagement.' + item.value)"
                :value=item.value>
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <!-- 这部分比较乱了，没时间改，强制兼容一下吧，初始值是0,改成null可能有问题-->
            <div class="singleText" v-show="show === 0 && fieldType.value != 'OPTION'">
              <div class="singleTextInput">
                <el-input v-model="singleText" autocomplete="off" :placeholder="$t('fieldManagement.singleTextPla')"
                  size="small"></el-input>
              </div>
              <div class="singleTextText">
                <div class="text" style="margin-right: 8px">{{ $t('fieldManagement.singleText') }}</div>
              </div>
              <img
                :src="$i18n.locale === 'en' ? require('@/assets/images/en_example.png') : require('@/assets/images/u2434.png')"
                class="fieldImg" />
            </div>
            <div class="multiLineText" v-show="show === 1">
              <div class="mulInput">
                <el-input v-model="MuliteText" :placeholder="$t('fieldManagement.multiLineText')" type="textarea"
                  :rows="2" size="mini">
                </el-input>
              </div>
              <div class="text" style="margin-right: 8px">{{ $t('fieldManagement.multiLineText') }}</div>
              <img
                :src="$i18n.locale === 'en' ? require('@/assets/images/en_example.png') : require('@/assets/images/u2434.png')"
                class="fieldImg" />
            </div>
            <div class="block" v-show="show === 2">

              <div class="dataPicker">
                <el-date-picker class="data" v-model="datePicker" type="date"
                  :placeholder="$t('fieldManagement.datePlaceholder')" format="yyyy 年 MM 月 dd 日" value-format="yyyy-MM-dd"
                  style="width: 100%">
                </el-date-picker>
              </div>
              <div class="dataPickerText" style="margin-right: 8px">{{ $t('fieldManagement.dataPickerText') }}:{{
                datePicker
              }}
              </div>


              <img
                :src="$i18n.locale === 'en' ? require('@/assets/images/en_example.png') : require('@/assets/images/u2434.png')"
                class="fieldImg" />
            </div>

            <div class="timeSelect" v-show="show === 3">

              <div class="timePicker">
                <el-time-picker v-model="time1" :placeholder="$t('fieldManagement.timePlaceholder')" format="HH:mm:ss"
                  value-format="HH:mm:ss" style="width: 100%">
                </el-time-picker>
              </div>
              <div class="timePickerText" style="margin-right: 8px">{{ $t('fieldManagement.timePickerText') }}:{{ time1 }}
              </div>
              <img
                :src="$i18n.locale === 'en' ? require('@/assets/images/en_example.png') : require('@/assets/images/u2434.png')"
                class="fieldImg" />
            </div>

            <div class="fieldSelect" v-show="show === 4 || fieldType.value == 'OPTION'">
              <div class="selectMore">
                <el-select v-model="select2" :placeholder="$t('common.selectPlaceholder')" style="width: 100%">
                  <el-option :label="$t('fieldManagement.option2')" :value="$t('fieldManagement.option2')"></el-option>
                  <el-option :label="$t('fieldManagement.option2')" :value="$t('fieldManagement.option2')"></el-option>
                </el-select>
              </div>


              <div class="selectMoreText" style="margin-right: 8px">{{ $t('fieldManagement.selectMoreText') }}</div>

              <img
                :src="$i18n.locale === 'en' ? require('@/assets/images/en_example.png') : require('@/assets/images/u2434.png')"
                class="fieldImg" />
            </div>

            <div class="fieldManRadio" v-show="show === 5">

              <div class="radioCheck" style="display: flex">
                <div style="flex: 0.33 0.33 0">
                  <el-radio v-model="radios" label="1">{{ $t('fieldManagement.option1') }}</el-radio>
                </div>
                <div style="flex: 0.33 0.33 0">
                  <el-radio v-model="radios" label="2">{{ $t('fieldManagement.option2') }}</el-radio>
                </div>
                <div style="flex: 0.33 0.33 0">
                  <el-radio v-model="radios" label="3">{{ $t('fieldManagement.option3') }}</el-radio>
                </div>
              </div>

              <div class="radioCheckText" style="margin-right: 8px">{{ $t('fieldManagement.selectMoreText') }}</div>

              <img
                :src="$i18n.locale === 'en' ? require('@/assets/images/en_example.png') : require('@/assets/images/u2434.png')"
                class="fieldImg" />
            </div>

            <div class="fieldCheckbox" v-show="show === 6">

              <div class="checkSelect">
                <el-checkbox-group v-model="checkSelect" style="width: 100%;display: flex">
                  <div style="flex: 0.33 0.33 0">
                    <el-checkbox :label="$t('fieldManagement.option1')"></el-checkbox>
                  </div>
                  <div style="flex: 0.33 0.33 0">
                    <el-checkbox :label="$t('fieldManagement.option2')"></el-checkbox>
                  </div>
                  <div style="flex: 0.33 0.33 0">
                    <el-checkbox :label="$t('fieldManagement.option3')"></el-checkbox>
                  </div>
                </el-checkbox-group>
              </div>
              <div class="checkSeleckText" style="margin-right: 8px">{{ $t('fieldManagement.checkSeleckText') }}</div>
              <img
                :src="$i18n.locale === 'en' ? require('@/assets/images/en_example.png') : require('@/assets/images/u2434.png')"
                class="fieldImg" />
            </div>

            <div class="cascade" v-show="show === 7">
              <div class="cascadeSelectProvince">
                <el-select v-model="selectProvince" :placeholder="$t('common.selectPlaceholder')">
                  <el-option label="北京市" value="北京市"></el-option>
                  <el-option label="上海市" value="上海市"></el-option>
                </el-select>
              </div>
              <div class="cascadeSelectCity">
                <el-select v-model="selectCity" :placeholder="$t('common.selectPlaceholder')">
                  <el-option label="朝阳区" value="朝阳区"></el-option>
                  <el-option label="海淀区" value="海淀区"></el-option>
                </el-select>
              </div>
              <div class="checkSeleckText">{{ $t('fieldManagement.checkCascadeText') }}</div>
              <img
                :src="$i18n.locale === 'en' ? require('@/assets/images/en_example.png') : require('@/assets/images/u2434.png')"
                class="fieldImg" />
            </div>
            <div v-show="show === 'FILE'">
              <div class="fileClass">
                <div style="margin: 0 10px">
                  <el-upload class="upload-demo" action="https://jsonplaceholder.typicode.com/posts/">
                    <div class="upload--picture-card">
                      <i class="el-icon-plus"></i>
                    </div>
                  </el-upload>

                </div>
                <div style="margin-right: 8px;text-align: right;white-space: nowrap;">{{ $t('fieldManagement.fileText') }}
                </div>
                <img
                  :src="$i18n.locale === 'en' ? require('@/assets/images/en_example.png') : require('@/assets/images/u2434.png')"
                  class="fieldImg" />
              </div>
              <!-- <div class="fileTypeClass">
                              <span style="margin-right: 12px">允许上传:</span>
                              <el-checkbox-group v-model="fileTypeCheck" @change="changeClick">
                                  <el-checkbox  v-for="item in fileTypeList" :label="item.value" :key="item.value">{{item.label}}</el-checkbox>
                              </el-checkbox-group>
                          </div>-->
              <div class="quantityLimit">
                <span style="margin-right: 12px">{{ $t('fieldManagement.quantityLimit') }}:</span>
                <el-radio-group v-model="fileLimit" style="display: flex;align-items: center">
                  <el-radio label="one">{{ $t('fieldManagement.one') }}</el-radio>
                  <el-radio label="more">{{ $t('fieldManagement.more') }}</el-radio>
                </el-radio-group>
                <div v-if="fileLimit === 'more'" style="display: flex;justify-content: flex-end">
                  <span style="margin-right: 10px">{{ $t('fieldManagement.maxQuantityLimit') }}:</span>
                  <el-input style="width: 40%" type="number" min="1" v-model="limitNum"
                    placeholder="请输入数量的最大上限"></el-input>个
                </div>
              </div>
            </div>

            <div class="options" v-show="show === 4">
              <p class="options_tips">选择方式</p>
              <div class="options_type">
                <el-radio-group v-model="fieldType.selectType" @change="changeSelectType">
                  <el-radio label="单选">单选</el-radio>
                  <el-radio label="多选">多选</el-radio>
                </el-radio-group>
              </div>
              <div class="select_header">
                <div class="s_h_name">{{ $t('fieldManagement.optionsName') }}</div>
                <div class="s_h_value">{{ $t('fieldManagement.optionsValue') }}</div>
                <div class="s_h_defalut">{{ $t('fieldManagement.optionsIsDefault') }}</div>
              </div>
              <div class="select_info" id="radioOption">
                <option-set v-for="(item, index) in radioOptions" :key="index" :index="index + 1" :index2="index"
                  @delClick="delClick" :content="item" :radioOptions="radioOptions"
                  @checkedChange="() => { selectCheckedChange(index) }" :selectType="fieldType.selectType"
                  @resortOptionSet="resortOptionSet('radioOption')" @mouseUpClick="mouseUpClick">
                </option-set>
              </div>
              <div class="select_add">
                <span class="add_btn" @click="addOptions">
                  <i class="el-icon-plus"></i>
                </span>
                <span @click="addOptions">{{ $t('fieldManagement.addOption') }}</span>
              </div>
            </div>
            <div class="options" v-show="show === 5">
              <div class="select_header">
                <div class="s_h_name">{{ $t('fieldManagement.optionsName') }}</div>
                <div class="s_h_value">{{ $t('fieldManagement.optionsValue') }}</div>
                <div class="s_h_defalut">{{ $t('fieldManagement.optionsIsDefault') }}</div>
              </div>
              <div class="select_info" id="radioOption2">
                <option-set v-for="(item, index) in radioOptions" :key="index" :index="index + 1" :index2="index"
                  selectType="单选" @delClick="delClick" :content="item" :radioOptions="radioOptions"
                  @checkedChange="() => { selectCheckedChange(index) }"
                  @resortOptionSet="resortOptionSet('radioOption2')" @mouseUpClick="mouseUpClick">
                </option-set>
              </div>
              <div class="select_add">
                <span class="add_btn" @click="addOptions">
                  <i class="el-icon-plus"></i>
                </span>
                <span @click="addOptions">{{ $t('fieldManagement.addOption') }}</span>
              </div>
            </div>
            <div class="options" v-show="show === 6">
              <div class="select_header">
                <div class="s_h_name">{{ $t('fieldManagement.optionsName') }}</div>
                <div class="s_h_value">{{ $t('fieldManagement.optionsValue') }}</div>
                <div class="s_h_defalut">{{ $t('fieldManagement.optionsIsDefault') }}</div>
              </div>
              <div class="select_info" id="radioOption3">
                <option-set v-for="(item, index) in radioOptions" :key="index" :index="index + 1" :index2="index"
                  @delClick="delClick" :content="item" :radioOptions="radioOptions" @radioChange="radioChange"
                  selectType="多选" @resortOptionSet="resortOptionSet('radioOption3')" @mouseUpClick="mouseUpClick">
                </option-set>
              </div>
              <div class="select_add">
                <span class="add_btn" @click="addOptions">
                  <i class="el-icon-plus"></i>
                </span>
                <span @click="addOptions">{{ $t('fieldManagement.addOption') }}</span>
              </div>
            </div>

            <div class="cascadeOption" v-if="show === 7">
              <p class="options_tips">选择方式</p>
              <div class="options_type">
                <el-radio-group v-model="fieldType.selectType" @change="changeSelectType">
                  <el-radio label="单选">单选</el-radio>
                  <el-radio label="多选">多选</el-radio>
                </el-radio-group>
              </div>
              <div class="select_header">
                <div class="s_h_name">{{ $t('fieldManagement.optionsName') }}</div>
                <div class="s_h_value">{{ $t('fieldManagement.optionsValue') }}</div>
                <div class="s_h_defalut">{{ $t('fieldManagement.optionsIsDefault') }}</div>
              </div>
              <div class="select_info" id="radioOption4">
                <cascadeOption v-for="(option, index) in optionValue" :key="index" :option="option"
                  :selectType="fieldType.selectType"
                  @addNode="(type, borIndex, threeIndex) => { addOptionNode(type, borIndex, threeIndex, index) }"
                  @delClick="(pIndex, pThreeIndex) => { delOptionNode(index, pIndex, pThreeIndex) }"
                  @checkedChange="checkedChange" @resortOptionSet="resortOptionSet('radioOption4')"
                  @mouseUpClick="mouseUpClick">
                </cascadeOption>
              </div>
              <div class="select_add">
                <span class="add_btn" @click="addCascadeOption">
                  <i class="el-icon-plus"></i>
                </span>
                <span @click="addCascadeOption">{{ $t('fieldManagement.addOption') }}</span>
              </div>
            </div>
            <div v-show="show === 'EXPLANATION'">
              <div class="fileClass">
                <div style="margin: 0 10px">
                  {{ $t('fieldManagement.explanation') }}
                </div>
                <img
                  :src="$i18n.locale === 'en' ? require('@/assets/images/en_example.png') : require('@/assets/images/u2434.png')"
                  class="fieldImg" />
              </div>
              <div>
                <div style="margin-right: 12px;text-align: left">{{ $t('fieldManagement.explan') }}:</div>
                <div>
                  <el-input type="textarea" :autosize="{ minRows: 2, maxRows: 4 }" v-model="descriptionText"
                    :placeholder="$t('fieldManagement.explanationPlaceholder')"></el-input>
                </div>
              </div>
            </div>
          </el-form-item>
          <el-form-item label="是否备注" v-if="fieldType.value !== 'EXPLANATION'">
            <div slot="label" class="form-label">
              <div class="label-left">
                <span style="margin-right: 4px">{{ $t('fieldManagement.remarks') }}</span>
                <el-switch v-model="remark.enable" active-color="#366aff">
                </el-switch>
              </div>
              <span class="label-right">
                {{ $t('fieldManagement.remarksTip') }}
              </span>
            </div>
            <el-input v-model="remark.text" :placeholder="$t('fieldManagement.textPlaceholder')"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div slot="dialog-footer">
        <el-button @click="cacelModifyField">{{ $t('common.cancel') }}</el-button>
        <el-button type="primary" @click="modifyField">{{ $t('common.save') }}</el-button>
      </div>
    </popup>
    <el-dialog :title="$t('fieldManagement.deleteField')" :visible.sync="dialogVisible" custom-class="fieldDialogClass"
      :close-on-click-modal="false" style="text-align: left;" width="500px">
      <span>{{ $t('fieldManagement.deleteFieldTip') }}</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">{{ $t('common.cancel') }}</el-button>
        <el-button type="danger" @click="deleteField">{{ $t('common.deleteText') }}</el-button>
      </span>
    </el-dialog>

    <el-dialog :title="$t('fieldManagement.deleteChild')" :visible.sync="dialogFormVisibleDelete2"
      :close-on-click-modal="false" custom-class="deleteField" width="500px">
      <div class="fieldDialogDelText" style="text-align: left">{{ $t('fieldManagement.deleteChildTip') }}</div>

      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisibleDelete2 = false">{{ $t('common.cancel') }}</el-button>
        <el-button type="danger" @click="deleteAllOption">{{ $t('common.deleteText') }}</el-button>
      </div>
    </el-dialog>
    <el-dialog :title="$t('fieldManagement.deleteChild')" :close-on-click-modal="false"
      :visible.sync="dialogFormVisibleDelete" custom-class="deleteField" width="500px">
      <div class="fieldDialogDelText" style="text-align: left">{{ $t('fieldManagement.deleteChildTip') }}</div>

      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisibleDelete = false">{{ $t('common.cancel') }}</el-button>
        <el-button type="danger" @click="deleteOption()">{{ $t('common.deleteText') }}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Sortable from "sortablejs";
import OptionSet from "./optionset/OptionSet";
import popup from "../../components/popup.vue";
import cascadeOption from "./optionset/cascadeOption.vue";
import { v4 as uuidv4 } from "uuid";

export default {
  name: "fieldManageItem",
  data () {
    return {
      descriptionText: "",
      dialogVisible: false,
      dialogFormVisible1: false,
      dialogFormVisibleDelete2: false,
      dialogFormVisibleDelete: false,
      formLabelWidth: '120px',
      labelPosition: 'top',
      rules: {
        name: [
          { required: true, message: '请输入字段名称', trigger: 'blur' },
          { max: 8, message: '只能输入8个字符', trigger: 'blur' }
        ],
      },
      form: {
        name: '',
        region: '',
        date1: '',
        date2: '',
        delivery: false,
        type: [],
        resource: '',
        desc: ''
      },
      show: 0,
      fieldType: {
        value: 'INPUT',
        label: '单行文本',
        selectType: '单选'
      },
      fieldTypes: [
        {
          label: '单行文本',
          value: 'INPUT'
        },
        {
          label: '多行文本',
          value: 'TEXTAREA'
        },
        {

          label: '下拉列表',
          value: 'SELECT'
        },
        {

          label: '级联选择',
          value: 'CASCADER'
        },
        {

          label: '单选框',
          value: 'RADIO'
        },
        {

          label: '复选框',
          value: 'CHECKBOX'
        },
        {

          label: '日期选择',
          value: 'DATE_PICKER'
        },
        {

          label: '时间选择',
          value: 'TIME_PICKER'
        },
        {
          label: '附件',
          value: 'FILE'
        },
        {
          label: '解释说明',
          value: 'EXPLANATION'
        },
        {
          label: '资产',
          value: 'OPTION'
        }
        /*  {
            label: '日期范围',
            value: 'DATE_RANGE'
          },
          {
            label: '时间范围',
            value: 'TIME_RANGE'
          },
          {
            label:'日期和时间范围',
            value:'DATETIME_RANGE'
          }*/
      ],
      selectProvince: '',
      selectCity: '',
      checkSelect: ['选项1', '选项2'],
      radios: '1',
      select2: '',
      time1: '12:40:15',
      datePicker: '2020-08-02',
      singleText: '',
      MuliteText: '',
      fieldName: '',
      fieldDescription: '',
      dateValue: [new Date(2020, 9, 10, 8, 40), new Date(2020, 10, 10, 9, 40)],
      timeValue: [new Date(2020, 9, 10, 10, 40), new Date(2020, 9, 10, 11, 40)],
      radioOptions: [{ value: '', label: '', checked: false }, { value: '', label: '', checked: false }],
      cascadeOptionSelect: [],
      showInput1: 0,
      showInput2: 0,
      showInput3: 0,
      optionValue: [],
      cascadeOptionSelect1: '',
      cascadeOptionSelect2: '',
      cascadeOptionSelect3: '',
      addCascade: 1,
      addCascade2: 1,
      addCascade1: 1,
      selectOption1: [],
      optionName: '',
      optionName2: '',
      optionName3: "",
      cascadeOptionChildren: "",
      OptionChildren: [],
      OptionChildren2: [],
      cascadeOptionChildren2: '',
      fristOption: 1,
      secondOption: 1,
      thirdOption: 1,
      fristName: '',
      secondName: '',
      thirdName: '',
      num2: 0,
      deleteOption1: 1,
      deleteOption2: 1,
      deleteOption2Index: '',
      deleteOptionIndex: '',
      rivese1: '',
      rivese2: '',
      rivese3: '',
      showOption1: '',
      showOption2: '',
      showOption3: '',
      riveseName1: '',
      riveseName2: '',
      riveseName3: '',
      riveseIndex1: '',
      riveseIndex2: '',
      riveseIndex3: '',
      radioOptionsTest: [],
      cascadeOptionSelect1Num: 0,
      cascadeOptionSelect2Num: 0,
      cascadeOptionSelect3Num: 0,
      dateTimeValue: '',
      fileTypeList: [
        { label: '图片', value: 'PICTURE' },
        { label: '视频', value: 'VIDEO' },
        { label: '音频', value: 'AUDIO' },
      ],
      fileTypeCheck: ['PICTURE'],  //允许上传的文件类型
      fileLimit: 'one',
      limitNum: 1,
      remark: {
        enable: false,
        text: ""
      },  //字段是否备注

    }
  },
  components: { OptionSet, popup, cascadeOption },
  props: {
    fieldList: {
      type: Array,
      default () {
        return []
      }
    }
  },
  filters: {

  },
  watch: {
    fieldList: function (a) {
      this.fieldList = a
    }
  },
  mounted () {
  },
  methods: {
    typeLable (value) {
      if (value === 'INPUT')
        return this.$t('fieldManagement.' + value)
      if (value === 'TEXTAREA')
        return this.$t('fieldManagement.' + value)
      if (value === 'SELECT')
        return this.$t('fieldManagement.' + value)
      if (value === 'CASCADER')
        return this.$t('fieldManagement.' + value)
      if (value === 'RADIO')
        return this.$t('fieldManagement.' + value)
      if (value === 'CHECKBOX')
        return this.$t('fieldManagement.' + value)
      if (value === 'DATE_PICKER')
        return this.$t('fieldManagement.' + value)
      if (value === 'TIME_PICKER')
        return this.$t('fieldManagement.' + value)
      if (value === 'FILE')
        return this.$t('fieldManagement.' + value)
      if (value === 'EXPLANATION') { // 新增类型 解释说明
        return this.$t('fieldManagement.' + value)
      }
      if (value == 'OPTION') { // 新增类型 资产
        return this.$t('fieldManagement.' + value)
      }
    },
    getRowClass () {
      return 'fieldRowClass'
    },
    indexMethod (index) {
      return index + 1
    },
    selectType (value) {
      if (value === 'INPUT') {
        this.show = 0
      }
      if (value === 'TEXTAREA') {
        this.show = 1
      }
      if (value === 'DATE_PICKER') {
        this.show = 2
      }
      if (value === 'TIME_PICKER') {
        this.show = 3

      }
      if (value === 'SELECT') {
        this.show = 4;
      }
      if (value === 'RADIO') {
        this.show = 5
      }
      if (value === 'CHECKBOX') {
        this.show = 6
      }
      if (value === 'CASCADER') {
        this.show = 7
      }
      if (value === 'FILE') {
        this.show = 'FILE'
      }
      if (value === 'EXPLANATION') {
        this.show = 'EXPLANATION'
      }
      if (value === 'OPTION') {
        this.show = value;
      }
      this.stepBar = 0;
      this.active = 1;
      this.fieldType.value = value;
      this.fieldType.label = value;
      this.radioOptions = [{ value: '', label: '', checked: false }, { value: '', label: '', checked: false }]
      this.$set(this, 'optionValue', [{
        value: '',
        label: '',
        key: uuidv4(),
        checked: false,
        children: []
      }])
    },
    selectCheckedChange (index) {
      if (this.fieldType.selectType == '单选') {
        this.radioOptions.forEach((item, i) => {
          item.checked = index == i
        })
      }
    },
    //点击编辑图标的事件
    reviseField (id, fieldDetail) {
      this.$emit('reviseField')
      console.log(fieldDetail)
      this.fieldId = id
      this.fieldName = fieldDetail.name
      this.fieldDescription = fieldDetail.description
      this.fieldType.value = fieldDetail.type
      this.remark = fieldDetail.extInfo.remark ? fieldDetail.extInfo.remark : {
        enable: false,
        text: ""
      }
      this.OptionChildren2 = []
      this.OptionChildren = []
      this.showInput1 = 0
      this.showInput2 = 0
      this.showInput3 = 0
      if (fieldDetail.type === 'EXPLANATION') {
        this.show = 'EXPLANATION';
        this.descriptionText = fieldDetail.extInfo.descriptionText;
      }
      if (fieldDetail.type === 'INPUT') {
        this.show = 0
      }
      if (fieldDetail.type === 'TEXTAREA') {
        this.show = 1
      }
      if (fieldDetail.type === 'DATE_PICKER') {
        this.show = 2
      }
      if (fieldDetail.type === 'TIME_PICKER') {
        this.show = 3
      }
      if (fieldDetail.type === 'SELECT') {
        this.show = 4
        this.radioOptions = fieldDetail.extInfo.option
        this.fieldType.selectType = fieldDetail.extInfo.selectType ? fieldDetail.extInfo.selectType : '单选'
        this.oldDataProcessing('SELECT')
      }
      if (fieldDetail.type === 'RADIO') {
        this.show = 5
        this.radioOptions = fieldDetail.extInfo.option
        this.fieldType.selectType = '单选'
        this.oldDataProcessing('RADIO')
      }
      if (fieldDetail.type === 'CHECKBOX') {
        this.show = 6
        this.radioOptions = fieldDetail.extInfo.option
        this.fieldType.selectType = '多选'
        this.oldDataProcessing('CHECKBOX')
      }
      if (fieldDetail.type === 'FILE') {
        this.show = 'FILE'
        console.log(fieldDetail.extInfo)
        this.fileLimit = fieldDetail.extInfo.fileLimit
        this.limitNum = fieldDetail.extInfo.limitNum
      }
      if (fieldDetail.type === 'CASCADER') {
        this.show = 7
        this.addCascade1 = 1
        this.addCascade2 = 1
        this.addCascade = 1
        this.optionValue = fieldDetail.extInfo.cascade
        this.fieldType.selectType = fieldDetail.extInfo.selectType ? fieldDetail.extInfo.selectType : '单选'
        this.oldDataProcessing('CASCADER')

        // fieldDetail.extInfo.cascade.forEach((item) => {
        //   if (fieldDetail.extInfo.cascade[0] !== '') {
        //     if (fieldDetail.extInfo.cascade[0].children !== undefined) {
        //       this.addCascade = 2
        //       if (fieldDetail.extInfo.cascade[0].children.length > 0) {
        //         this.cascadeOptionSelect1 = fieldDetail.extInfo.cascade[0].value
        //         this.OptionChildren = fieldDetail.extInfo.cascade[0].children
        //         this.cascadeOptionSelect2 = fieldDetail.extInfo.cascade[0].children[0].value
        //         fieldDetail.extInfo.cascade[0].children.forEach(items => {
        //           if (items.children !== undefined) {
        //             this.addCascade2 = 3
        //           }
        //         })
        //         if (fieldDetail.extInfo.cascade[0].children[0].children !== undefined) {
        //           if (fieldDetail.extInfo.cascade[0].children[0].children.length > 0) {
        //             console.log(fieldDetail.extInfo.cascade[0].children[0])
        //             this.cascadeOptionSelect3 = fieldDetail.extInfo.cascade[0].children[0].children[0].value
        //             this.OptionChildren2 = fieldDetail.extInfo.cascade[0].children[0].children
        //           }
        //           else
        //             this.cascadeOptionSelect3 = ''
        //         }
        //       }
        //       else {
        //         this.cascadeOptionSelect2 = ''
        //       }
        //     }
        //     else {
        //       this.cascadeOptionSelect1 = fieldDetail.extInfo.cascade[0].value
        //     }
        //   }
        // })
      }
      if (fieldDetail.type === 'DATETIME_RANGE') {
        this.show = 8
      }
      this.dialogFormVisible1 = true
    },
    changeSelectType () {
      this.radioOptions.forEach(item => {
        item.checked = false
      })
      this.loopChangeCheck(this.optionValue, '')
      this.$forceUpdate()
    },
    oldDataProcessing (type) {
      if (type == 'SELECT' || type == 'RADIO' || type == 'CHECKBOX') {
        this.radioOptions.forEach(item => {
          if (item._default) {
            this.$set(item, 'checked', item._default === 'true')
            delete item._default
          }
        })
      } else {
        this.cascadeOldDataSet(this.optionValue)
      }
    },
    cascadeOldDataSet (list) {
      if (list) {
        list.forEach(item => {
          if (item.checked === undefined) {
            this.$set(item, 'checked', false)
          }
          item.key = uuidv4()
          this.cascadeOldDataSet(item.children)
        })
      }
    },
    //保存修改字段按钮
    modifyField () {
      if (this.fieldName === '') {
        this.$message({
          message: this.$t('fieldManagement.fieldNameNull'),
          type: 'error',
          duration: 1000
        })
        return
      }
      if (this.fileLimit === 'more' && this.limitNum === '') {
        this.$message({
          message: this.$t('fieldManagement.maxUploadNumber'),
          type: 'error',
          duration: 2000
        })
        return
      }
      let extInfo
      let radioOptionsList = []
      if (this.fieldType.value === 'RADIO' || this.fieldType.value === 'CHECKBOX' || this.fieldType.value === 'SELECT') {
        this.radioOptions.forEach(item => {
          if (item.label !== '')
            radioOptionsList.push(item)
        })
        // 做的新老数据的兼容，旧的数据太多了洗不了，做字段转换维护
        extInfo = {
          option: radioOptionsList.map(opt => {
            if(opt.checked) {
              opt._default = 'true'
            } else {
              opt._default = 'false'
            }
            delete opt.checked
            return opt
          }),
          value: [],
          selectType: this.fieldType.selectType,
          WebHookParameter: [],
          remark: this.remark
        }
        if (radioOptionsList.length === 0) {
          this.$message({
            message: this.$t('fieldManagement.minOption'),
            type: 'error',
            duration: 2000
          })
          return;
        }
      } else if (this.fieldType.value === 'OPTION') {
        extInfo = {
          option: [],
          value: [],
          WebHookParameter: [],
          remark: this.remark
        }
      } else if (this.fieldType.value === 'CASCADER') {
        let fieldValue = this.setDefalutValue(this.fieldType.selectType)
        extInfo = {
          cascade: this.optionValue,
          value: [],
          selectType: this.fieldType.selectType,
          WebHookParameter: [],
          remark: this.remark,
          fieldValue: fieldValue
        }
      }
      else if (this.fieldType.value === 'FILE') {
        extInfo = {
          fileLimit: this.fileLimit,
          WebHookParameter: [],
          remark: this.remark
        }
        if (this.fileLimit === 'more') {
          extInfo.limitNum = this.limitNum
        }
      }
      else if (this.fieldType.value === 'EXPLANATION') {
        extInfo = {
          descriptionText: ""
        }
        if (this.descriptionText.trim() === '') {
          this.$message({
            message: this.$t('fieldManagement.explanationNull'),
            type: 'error',
            duration: 2000
          })
          return;
        } else {
          extInfo.descriptionText = this.descriptionText.trim();
        }
      }
      else {
        extInfo = {
          value: '',
          WebHookParameter: [],
          remark: this.remark
        }
      }
      let field = {
        description: this.fieldDescription,
        extInfo: extInfo,
        name: this.fieldName,
        type: this.fieldType.value
      }
      this.FetchPut('/api/form/field/', this.fieldId, field).then(res => {
        if (res.code === '0') {
          this.$message.success(this.$t('fieldManagement.updateFieldSuccess'))
          this.$emit('modifyField')
          this.dialogFormVisible1 = false
        }
      })

    },
    setDefalutValue (selectType) {
      let fieldValue = []
      // 这里懒得改递归了，固定三层结构，就循环一下吧
      this.optionValue.forEach(item => {
        delete item.key
        if (item.checked) {
          if (selectType == '单选') {
            fieldValue = [{
              value: item.value,
              label: item.label,
              checked: item.checked
            }]
          } else {
            fieldValue.push({
              value: item.value,
              label: item.label,
              checked: item.checked
            })
          }
        }
        if (item.children !== undefined) {
          if (item.children.length === 0) {
            delete item.children
          } else {
            item.children.forEach(items => {
              delete items.key
              if (items.checked) {
                if (selectType == '单选') {
                  fieldValue = [{
                    value: items.value,
                    label: items.label,
                    checked: items.checked
                  }]
                } else {
                  fieldValue.push([item.value, items.value])
                }
              }
              if (items.children !== undefined) {
                if (items.children.length === 0) {
                  delete items.children
                } else {
                  items.children.forEach(it => {
                    delete it.key
                    if (it.checked) {
                      if (selectType == '单选') {
                        fieldValue = [{
                          value: it.value,
                          label: it.label,
                          checked: it.checked
                        }]
                      } else {
                        fieldValue.push([item.value, items.value, it.value])
                      }
                    }
                  })
                }
              }
            })
          }
        }
      })
      return fieldValue
    },
    cacelModifyField () {
      this.dialogFormVisible1 = false
    },

    //删除字段
    deleteField () {
      this.FetchDelete('/api/form/field/', this.fieldId).then(res => {
        if (res.code === '0') {
          this.$message.success(this.$t('fieldManagement.deleteFieldSuccess'))
          this.$emit('deleteField')
          this.dialogVisible = false
        }
      })
    },
    deleteOpenDialog (id) {
      this.fieldId = id
      this.dialogVisible = true
    },

    delClick (index) {
      this.radioOptions.splice(index - 1, 1);

    },
    radioChange (index) {

      for (let i = 0; i < this.radioOptions.length; i++) {
        this.radioOptions[i]._default = 'true'
      }
      this.radioOptions[index]._default = 'false'
    },

    resortOptionSet (radioOptionEl) {
      let _this = this;
      let el = document.getElementById(radioOptionEl);
      el && new Sortable(el, {
        ghostClass: 'blue-background-class',
        animation: 200,
        sort: true,
        handle: '.handles',
        onUpdate: function (event) {
          //修改items数据顺序
          let newIndex = event.newIndex,
            oldIndex = event.oldIndex,
            $li = el.children[newIndex],
            $oldLi = el.children[oldIndex];
          // 先删除移动的节点
          el.removeChild($li)
          // 再插入移动的节点到原有节点，还原了移动的操作
          if (newIndex > oldIndex) {
            el.insertBefore($li, $oldLi)
          } else {
            el.insertBefore($li, $oldLi.nextSibling)
          }
          // 更新items数组
          if (radioOptionEl == 'radioOption4') {
            let item = _this.optionValue.splice(oldIndex, 1)
            _this.optionValue.splice(newIndex, 0, item[0])
          } else {
            let item = _this.radioOptions.splice(oldIndex, 1)
            _this.radioOptions.splice(newIndex, 0, item[0])
          }
          // 下一个tick就会走patch更新
        },
      });
    },
    mouseUpClick () { },

    //添加选项
    addOptions () {
      this.radioOptions.push({ value: '', label: '', checked: false });
    },
    addCascadeOption () {
      this.$set(this.optionValue, this.optionValue.length, {
        label: '',
        value: '',
        key: uuidv4(),
        checked: false,
        children: []
      })
      console.log(this.optionValue);
    },
    addOptionNode (type, borIndex, threeIndex, index) {
      if (borIndex === undefined && threeIndex === undefined) {
        // 添加子级时取消当前的默认选中状态
        this.$set(this.optionValue[index], 'checked', false)
        if(!this.optionValue[index].children) {
          this.$set(this.optionValue[index], 'children', [])
        }
        let count = this.optionValue[index].children.length ? this.optionValue[index].children.length : 0
        this.$set(this.optionValue[index].children, count, {
          label: '',
          value: '',
          checked: false,
          key: uuidv4(),
          children: []
        })
        return
      }
      if (borIndex !== undefined && threeIndex === undefined) {
        if (type == "brother") {
          if(!this.optionValue[index].children) {
            this.$set(this.optionValue[index], 'children', [])
          }
          let count = this.optionValue[index].children.length ? this.optionValue[index].children.length : 0
          this.$set(this.optionValue[index].children, count, {
            label: '',
            value: '',
            checked: false,
            key: uuidv4(),
            children: []
          })
        } else {
          if(!this.optionValue[index].children[borIndex].children) {
            this.$set(this.optionValue[index].children[borIndex], 'children', [])
          }
          // 添加子级时取消当前的默认选中状态
          this.$set(this.optionValue[index].children[borIndex], 'checked', false)
          let count = this.optionValue[index].children[borIndex].children.length
          this.$set(this.optionValue[index].children[borIndex].children, count, {
            label: '',
            value: '',
            checked: false,
            key: uuidv4(),
          })
        }
        return
      }
      if (threeIndex !== undefined) {
        if(!this.optionValue[index].children[borIndex].children) {
          this.$set(this.optionValue[index].children[borIndex], 'children', [])
        }
        let count = this.optionValue[index].children[borIndex].children.length
        this.$set(this.optionValue[index].children[borIndex].children, count, {
          label: '',
          value: '',
          checked: false,
          key: uuidv4(),
        })
      }
    },
    checkedChange (key) {
      console.log(key, this.optionValue, 'this.optionValue');
      this.loopChangeCheck(this.optionValue, key)
    },
    loopChangeCheck (list, key) {
      if (list) {
        for (let index = 0; index < list.length; index++) {
          const item = list[index];
          if (item.key == key) {
            item.checked = true
          } else {
            item.checked = false
          }
          this.loopChangeCheck(item.children, key)
        }
      }

    },
    delOptionNode (index, pIndex, pThreeIndex) {
      if (pIndex === undefined && pThreeIndex === undefined) {
        this.optionValue.splice(index, 1)
        return
      }
      if (pIndex !== undefined && pThreeIndex === undefined) {
        this.optionValue[index].children.splice(pIndex, 1)
        return
      }
      if (pIndex !== undefined && pThreeIndex !== undefined) {
        this.optionValue[index].children[pIndex].children.splice(pIndex, 1)
        return
      }
    },

    change (label) {
      this.radio = label
    },

    deleteAllOption () {
      if (this.fristOption === 0) {
        this.optionValue = []
        this.OptionChildren2 = []
        this.OptionChildren = []
        this.fristOption = 1
        this.cascadeOptionSelect1 = ''
        this.fristOption = 1
        this.addCascade2 = 0
        this.addCascade = 0

      }
      if (this.secondOption === 0) {
        this.OptionChildren = []
        this.OptionChildren2 = []
        this.optionValue.forEach(item => {
          item.children = []
          this.secondOption = 1
          this.cascadeOptionSelect2 = ''
          this.addCascade2 = 0
          this.addCascade = 0
        })

      }
      this.dialogFormVisibleDelete2 = false
    },
    deleteOption () {
      if (this.deleteOption1 === 2) {
        console.log(this.optionValue)
        console.log(this.fristName)
        this.optionValue.forEach(item => {
          if (item.value === this.fristName) {
            this.optionValue.splice(this.deleteOptionIndex, 1)
            this.OptionChildren = []
            this.OptionChildren2 = []
            this.cascadeOptionSelect1 = ''
            this.cascadeOptionSelect2 = ''
            this.cascadeOptionSelect3 = ''
          }

        })
        this.deleteOption1 = 0


        console.log(this.optionValue)
        console.log(this.OptionChildren)
      }
      if (this.deleteOption2 === 2) {
        this.optionValue.forEach(item => {
          console.log(this.cascadeOptionSelect1)
          if (item.value === this.cascadeOptionSelect1) {
            item.children.forEach(items => {
              items.children.splice(this.deleteOption2Index, 1)
              this.OptionChildren.splice(this.deleteOption2Index, 1)
              this.OptionChildren2 = []
              this.cascadeOptionSelect2 = ''
              this.cascadeOptionSelect3 = ''
            })
          }
        })

        if (this.cascadeOptionSelect2 === this.secondName) {
          this.cascadeOptionSelect3 = ''
        }

        this.deleteOption2 = 0
      }
      this.dialogFormVisibleDelete = false

    },
    changeClick (value) {
      console.log(value)
    },
  }
}
</script>

<style scoped lang="less">
.dialog_form {
  .options_tips {}

  .options_type {
    width: 100%;
    padding: 0 12px;
    background: #F6F8FD;
    box-sizing: border-box;
    border-radius: 5px;
  }

  .select_header {
    width: 100%;
    height: 32px;
    display: flex;
    align-items: center;
    margin-top: 10px;

    .s_h_name {
      width: 232px;
      padding-left: 5px;
      line-height: 32px;
      box-sizing: border-box;
    }

    .s_h_value {
      width: 232px;
      padding-left: 5px;
      line-height: 32px;
      box-sizing: border-box;
    }

    .s_h_defalut {
      width: 60px;
      line-height: 32px;
    }
  }

  .select_info {
    width: 100%;
    // background: #F6F8FD;
    // box-sizing: border-box;
    border-radius: 5px;
    padding: 5px 0;
  }

  .select_add {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: #366AFF;

    .add_btn {
      width: 28px;
      height: 28px;
      background: #366AFF;
      border-radius: 5px;
      color: #ffffff;
      font-size: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 8px;
      cursor: pointer;
    }

    span {
      cursor: pointer;
    }

  }

  /deep/.el-form-item {
    width: 100%;

    .el-form-item__label {
      width: 100%;
    }
  }

  /deep/.form-label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    .label-left {
      display: flex;
      align-items: center;
      width: 100px;
      flex: none;
    }

    .label-right {
      color: #C0C4CC;
    }
  }
}

.el-form-item__content {
  text-align: left;
}

.fieldRowClass {
  font-family: "Arial Normal", "Arial", sans-serif;
  line-height: 72px;
}

.module-result-content-field {
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;

  .result-content-field {
    flex: 1;
    position: relative;

    .el-table {

      position: absolute;
      overflow: hidden;

    }
  }
}

.fieldDialogClass {
  min-width: 300px;
  width: 400px;
}

.el-dialog__body {
  text-align: left;
}

.blue-background-class {}

.el-form-item__label {
  line-height: 1;
}

.optionsSequence {
  text-align: center;

}

.optionsName {
  text-align: center;
}

.optionsValue {
  text-align: center;
}

.fileClass {
  /deep/.el-upload-list {
    display: none;
  }
}

.fileClass {
  justify-content: space-between;
  height: 56px;
  line-height: 56px;
  background-color: #FEF0DE;
  border-radius: 5px;
  border-width: 1px;
  border-style: solid;
  margin-bottom: 12px;
  border-color: rgba(245, 154, 35, 1);
  display: flex;
  position: relative;

  .upload-demo {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 56px;
  }

  .upload--picture-card {
    background-color: #fbfdff;
    border: 1px dashed #c0ccda;
    border-radius: 6px;
    width: 40px;
    height: 40px;
    line-height: 56px;
    vertical-align: top;
    cursor: pointer;
    text-align: center;
    font-size: 28px;
    color: #8c939d;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.fileTypeClass,
.quantityLimit {
  display: flex;
  justify-content: flex-start;
}
</style>