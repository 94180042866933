<template>
    <div class="optionset">
        <div class="optionSetNum handles" @mouseenter="resortOptionSet" @mouseover="mouseUpClick">
            <i class="iconfont guoran-tongyichicun-16-10-paixu"></i>
        </div>
        <div class="optionSetName">
            <el-input v-model="content.label" autocomplete="off" placeholder="请输入选项名称"></el-input>
        </div>
        <div class="optionSetValue">
            <el-input v-model="content.value" autocomplete="off" placeholder="请输入选项值"></el-input>
        </div>
        <div class="optionSetRadio">
            <el-radio v-if="selectType == '单选'" v-model="content.checked" :label="true" @change="checkedChange"></el-radio>
            <el-checkbox v-if="selectType == '多选'" v-model="content.checked" @change="checkedChange"></el-checkbox>
        </div>
        <div class="optionSetDel" @click="delClick(index)">
            <i class="iconfont guoran-tongyichicun-16-09-shanchu2"></i>
        </div>
    </div>
</template>

<script>
export default {
    name: "OptionSet",
    data () {
        return {
            option: [],
            input: {
                inputName: '',
                radio: ''
            },
            inputName: '',
            radio: '',
            checked: true,
            optionValueIsNull: '',
            addOption: ['1', '2'],
            value: 0,
        }
    },
    mounted () {
        this.radioOptions.forEach(item => {
            if (item._default === 'true')
                this.radio = item._default
        })
    },

    props: {
        content: {
            type: Object
        },
        index: {
            type: Number
        },
        item: {
            type: String
        },
        itemsExtInfo: {
            type: Object,
            default () {
                return {}
            }
        },
        selectOptions: Array,

        radioOptions: {
            type: Array,
            default () {
                return []
            }
        },
        defaultValue: String,
        index2: Number,
        selectType: String

    },
    methods: {

        delClick (index) {
            this.index1 = index
            this.$emit('delClick', this.index1)
        },
        change () {

        },
        /*添加下拉选项的值*/
        optionInputClick () {
            this.$emit('optionInputClick', this.inputName)
        },
        checkedChange () {
            this.$emit("checkedChange")
        },
        // radioChange (index) {
        //     for (let i = 0; i < this.radioOptions.length; i++) {
        //         this.radioOptions[i]._default = 'false'
        //     }

        //     this.radioOptions[index]._default = 'true'
        //     this.radio = this.radioOptions[index]._default
        // },
        resortOptionSet () {
            this.$emit('resortOptionSet')
        },
        mouseUpClick () {
            this.$emit('mouseUpClick')
        }
    },
    watch: {
        radioOptions: function (a) {
            a.forEach(item => {
                if (item._default === 'true')
                    this.radio = item._default
            })
        }
    }
}
</script>

<style scoped lang="less">
// @import "../../../assets/less/formManage/optionset/optionset.less";
.optionset {
    width: 100%;
    display: flex;
    align-items: center;
    margin: 5px 0;
    cursor: pointer;
    position: relative;

    .optionSetName {
        width: 232px;
    }

    .optionSetValue {
        width: 232px;
    }

    .optionSetRadio {
        width: 60px;
        display: flex;
        align-items: center;
        justify-content: center;

        /deep/.el-radio {
            .el-radio__label {
                display: none;
            }
        }
    }

    .el-input {
        width: 222px;
        margin: 0 5px;
    }

    .optionSetNum {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: -20px;

        i {
            font-size: 16px;
            color: #366aff;
            cursor: move;
        }
    }

    .optionSetDel {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: -20px;

        i {
            font-size: 16px;
            color: #366aff;
        }
    }
}

// .optionset:hover,
// .optionSetNum:hover,
// .optionSetDel:hover {

//     .optionSetNum,
//     .optionSetDel {
//         display: block;
//     }
// }
</style>