<template>
  <div id="formManage">
    <!-- <div class="content-header" style="width:100%">
            <div class="leftInfo">
                <span class="headerName">{{activePageInfo.headerName}}</span>
                <div class="new-add-button"><el-button type="primary" v-if="botVersion != 2 && RP_Visible('OPEN_FORM_FIELD_ADD')" @click="addFieldClick" style="margin-right: 24px">新增字段</el-button></div>

            </div>
        </div> -->
    <page-top-header>
      <div slot="top-header-left" class="top-header-left">
        <i class="arsenal_icon arsenaljicheng"></i>
        <span class="page-name">{{ $t('webHook.title') }}</span>
      </div>
      <div slot="top-header-right" class="top-header-right">
        <div class="new-add-button"><el-button type="primary" v-if="botVersion != 2 && RP_Visible('OPEN_FORM_FIELD_ADD')"
            @click="addFieldClick" style="margin-right: 24px">{{ $t('fieldManagement.addField') }}</el-button></div>
      </div>
    </page-top-header>
    <div class="formmanage-content">
      <div class="left-nav-menu" :style="$i18n.locale === 'en' ? 'width:180px' : ''">
        <el-menu active-text-color="#366AFF" :default-openeds="['/main/form']" :default-active="currentPath" router
          class="el-menu-vertical-demo">
          <el-menu-item index="/main/webhook" style="padding-left: 20px" v-if="RP_Visible('OPEN_WEBHOOK')"
            :class="{ activeClass: this.$route.path === '/main/webhook' }">
            <i class="el-icon-connection"></i>
            <span slot="title">Webhook</span>
          </el-menu-item>
          <el-submenu index="/main/form">
            <template slot="title">
              <i class="el-icon-set-up"></i>
              <span>{{ $t('webHook.customerForm') }}</span>
            </template>
            <el-menu-item-group>
              <el-menu-item index="/main/fieldManage" style="width:100%;"
                :class="{ activeClass: this.$route.path === '/main/fieldManage' }">{{ $t('webHook.fieldManagement')
                }}</el-menu-item>
              <el-menu-item index="/main/formManage" style="width:100%;"
                :class="{ activeClass: this.$route.path === '/main/formManage' }">{{ $t('webHook.formManagement')
                }}</el-menu-item>
            </el-menu-item-group>
          </el-submenu>
          <el-menu-item index="/main/epidemic" v-if="isShow"
            :class="{ activeClass: this.$route.path === '/main/epidemic', epidemic_view: true }">
            <template slot="title">
              <i class="arsenal_icon" style="font-size:16px;margin-right:10px">&#xed6d;</i>
              <span>{{ $t('webHook.epidemicAreaManagement') }}</span>
            </template>
          </el-menu-item>
        </el-menu>
      </div>
      <div class="right-content">
        <div v-if="botVersion == 2">
          <VersionTip tipIndex="4" />
        </div>
        <div v-else class="list-content">
          <field-manage-item :fieldList="fieldList" @modifyField="modifyField" @deleteField="deleteField"
            @reviseField="reviseField">
          </field-manage-item>

          <div style="position: absolute;bottom: 10px;right: 8px;left: 0;text-align: right;">
            <pagination :pageSize="fieldCurrentSize" :currentPage="fieldPage" :total="fieldTotal"
              @handleSizeChange="handleSizeChange" @currentChange="handleCurrentChange">
            </pagination>
          </div>
        </div>
      </div>
    </div>
    <popup v-if="dialogFormVisible" class="dialog_form" @closeEvent="dialogFormVisible = false">
      <div slot="popup-name" class="popup-name">{{ $t('fieldManagement.addField') }}</div>
      <div slot="popup-tip">{{ '新增表单字段' }}</div>
      <div slot="popup-con">
        <el-form :model="form" :label-position="labelPosition" style="text-align: left">
          <el-form-item :label="$t('fieldManagement.fieldName')" :label-width="formLabelWidth">
            <el-input v-model="fieldName" autocomplete="off" :placeholder="$t('fieldManagement.fieldNamePlaceholder')"
              maxlength="8" show-word-limit></el-input>
          </el-form-item>
          <el-form-item :label="$t('fieldManagement.fieldDesc')" :label-width="formLabelWidth" style="text-align: left">
            <el-input v-model="fieldDescription" autocomplete="off"
              :placeholder="$t('fieldManagement.fieldDescPlaceholder')"></el-input>
          </el-form-item>
          <el-form-item :label="$t('fieldManagement.fieldType')" :label-width="formLabelWidth" style="text-align: left">
            <el-select v-model="fieldType.value" :placeholder="$t('fieldManagement.fieldTypePlaceholder')"
              @change="selectType" style="width: 100%">
              <el-option v-for="item in fieldTypes" :key=item.value :label="$t('fieldManagement.' + item.value)"
                :value=item.value>
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <!-- 这部分比较乱了，没时间改，强制兼容一下吧，初始值是0,改成null可能有问题-->
            <div class="singleText" v-show="show === 0 && fieldType.value != 'OPTION'">
              <div class="singleTextInput">
                <el-input v-model="singleText" autocomplete="off" :placeholder="$t('fieldManagement.singleTextPla')"
                  size="small"></el-input>
              </div>
              <div class="singleTextText">
                <div class="text" style="margin-right: 8px">{{ $t('fieldManagement.singleText') }}</div>
              </div>
              <img
                :src="$i18n.locale === 'en' ? require('@/assets/images/en_example.png') : require('@/assets/images/u2434.png')"
                class="fieldImg" />
            </div>
            <div class="multiLineText" v-show="show === 1">
              <div class="mulInput">
                <el-input v-model="MuliteText" :placeholder="$t('fieldManagement.multiLineText')" type="textarea"
                  :rows="2" size="mini">
                </el-input>
              </div>
              <div class="text" style="margin-right: 8px">{{ $t('fieldManagement.multiLineText') }}</div>
              <img
                :src="$i18n.locale === 'en' ? require('@/assets/images/en_example.png') : require('@/assets/images/u2434.png')"
                class="fieldImg" />
            </div>
            <div class="block" v-show="show === 2">

              <div class="dataPicker">
                <el-date-picker class="data" v-model="datePicker" type="date"
                  :placeholder="$t('fieldManagement.datePlaceholder')" format="yyyy 年 MM 月 dd 日" value-format="yyyy-MM-dd"
                  style="width: 100%">
                </el-date-picker>
              </div>
              <div class="dataPickerText" style="margin-right: 8px">日期,&nbsp;如:{{ datePicker }}</div>
              <img
                :src="$i18n.locale === 'en' ? require('@/assets/images/en_example.png') : require('@/assets/images/u2434.png')"
                class="fieldImg" />
            </div>

            <div class="timeSelect" v-show="show === 3">
              <div class="timePicker">
                <el-time-picker v-model="time1" :placeholder="$t('fieldManagement.timePlaceholder')" format="HH:mm:ss"
                  value-format="HH:mm:ss" style="width: 100%">
                </el-time-picker>
              </div>
              <div class="timePickerText" style="margin-right: 8px">{{ $t('fieldManagement.timePickerText') }}:{{ time1 }}
              </div>
              <img
                :src="$i18n.locale === 'en' ? require('@/assets/images/en_example.png') : require('@/assets/images/u2434.png')"
                class="fieldImg" />
            </div>

            <div class="fieldSelect" v-show="show === 4 || fieldType.value == 'OPTION'">
              <div class="selectMore">
                <el-select v-model="select2" :placeholder="$t('common.selectPlaceholder')" style="width: 100%">
                  <el-option :label="$t('fieldManagement.option1')" :value="$t('fieldManagement.option2')"></el-option>
                  <el-option :label="$t('fieldManagement.option2')" :value="$t('fieldManagement.option2')"></el-option>
                </el-select>
              </div>


              <div class="selectMoreText" style="margin-right: 8px">{{ $t('fieldManagement.selectMoreText') }}</div>

              <img
                :src="$i18n.locale === 'en' ? require('@/assets/images/en_example.png') : require('@/assets/images/u2434.png')"
                class="fieldImg" />
            </div>

            <div class="fieldManRadio" v-show="show === 5">

              <div class="radioCheck" style="display: flex">
                <div style="flex: 0.33 0.33 0">
                  <el-radio v-model="radios" label="1">{{ $t('fieldManagement.option1') }}</el-radio>
                </div>
                <div style="flex: 0.33 0.33 0">
                  <el-radio v-model="radios" label="2">{{ $t('fieldManagement.option2') }}</el-radio>
                </div>
                <div style="flex: 0.33 0.33 0">
                  <el-radio v-model="radios" label="3">{{ $t('fieldManagement.option3') }}</el-radio>
                </div>
              </div>

              <div class="radioCheckText" style="margin-right: 8px">{{ $t('fieldManagement.selectMoreText') }}</div>

              <img
                :src="$i18n.locale === 'en' ? require('@/assets/images/en_example.png') : require('@/assets/images/u2434.png')"
                class="fieldImg" />
            </div>

            <div class="fieldCheckbox" v-show="show === 6">

              <div class="checkSelect">
                <el-checkbox-group v-model="checkSelect" style="width: 100%;display: flex">
                  <div style="flex: 0.33 0.33 0">
                    <el-checkbox :label="$t('fieldManagement.option1')"></el-checkbox>
                  </div>
                  <div style="flex: 0.33 0.33 0">
                    <el-checkbox :label="$t('fieldManagement.option2')"></el-checkbox>
                  </div>
                  <div style="flex: 0.33 0.33 0">
                    <el-checkbox :label="$t('fieldManagement.option3')"></el-checkbox>
                  </div>
                </el-checkbox-group>
              </div>
              <div class="checkSeleckText" style="margin-right: 8px">{{ $t('fieldManagement.checkSeleckText') }}</div>
              <img
                :src="$i18n.locale === 'en' ? require('@/assets/images/en_example.png') : require('@/assets/images/u2434.png')"
                class="fieldImg" />
            </div>

            <div class="cascade" v-show="show === 7">
              <div class="cascadeSelectProvince">
                <el-select v-model="selectProvince" :placeholder="$t('common.selectPlaceholder')">
                  <el-option label="北京市" value="北京市"></el-option>
                  <el-option label="上海市" value="上海市"></el-option>
                </el-select>
              </div>
              <div class="cascadeSelectCity">
                <el-select v-model="selectCity" :placeholder="$t('common.selectPlaceholder')">
                  <el-option label="朝阳区" value="朝阳区"></el-option>
                  <el-option label="海淀区" value="海淀区"></el-option>
                </el-select>
              </div>
              <div class="checkSeleckText">{{ $t('fieldManagement.checkCascadeText') }}</div>
              <img
                :src="$i18n.locale === 'en' ? require('@/assets/images/en_example.png') : require('@/assets/images/u2434.png')"
                class="fieldImg" />
            </div>
            <div v-show="show === 'FILE'">
              <div class="fileClass">
                <div style="margin: 0 10px">
                  <el-upload class="upload-demo" action="https://jsonplaceholder.typicode.com/posts/">
                    <div class="upload--picture-card">
                      <i class="el-icon-plus"></i>
                    </div>
                  </el-upload>

                </div>
                <div style="margin-right: 8px;text-align: right;white-space: nowrap;">{{ $t('fieldManagement.fileText') }}
                </div>
                <img
                  :src="$i18n.locale === 'en' ? require('@/assets/images/en_example.png') : require('@/assets/images/u2434.png')"
                  class="fieldImg" />
              </div>
              <div class="quantityLimit">
                <span style="margin-right: 12px">{{ $t('fieldManagement.quantityLimit') }}:</span>
                <el-radio-group v-model="fileLimit" style="display: flex;align-items: center">
                  <el-radio label="one">{{ $t('fieldManagement.one') }}</el-radio>
                  <el-radio label="more">{{ $t('fieldManagement.more') }}</el-radio>
                </el-radio-group>
                <div v-if="fileLimit === 'more'" style="display: flex;justify-content: flex-end">
                  <span style="margin-right: 10px">{{ $t('fieldManagement.maxQuantityLimit') }}:</span>
                  <el-input style="width: 40%" type="number" min="1" v-model="limitNum"
                    placeholder="请输入数量的最大上限"></el-input>个
                </div>
              </div>
            </div>

            <div class="options" v-show="show === 4">
              <p class="options_tips">选择方式</p>
              <div class="options_type">
                <el-radio-group v-model="fieldType.selectType" @change="changeSelectType">
                  <el-radio label="单选">单选</el-radio>
                  <el-radio label="多选">多选</el-radio>
                </el-radio-group>
              </div>
              <div class="select_header">
                <div class="s_h_name">{{ $t('fieldManagement.optionsName') }}</div>
                <div class="s_h_value">{{ $t('fieldManagement.optionsValue') }}</div>
                <div class="s_h_defalut">{{ $t('fieldManagement.optionsIsDefault') }}</div>
              </div>
              <div class="select_info" id="radioOption">
                <option-set v-for="(item, index) in radioOptions" :key="index" :index="index + 1" :index2="index"
                  @delClick="delClick" :content="item" :radioOptions="radioOptions"
                  @checkedChange="() => { selectCheckedChange(index) }" :selectType="fieldType.selectType"
                  @resortOptionSet="resortOptionSet('radioOption')" @mouseUpClick="mouseUpClick">
                </option-set>
              </div>
              <div class="select_add">
                <span class="add_btn" @click="addOptions">
                  <i class="el-icon-plus"></i>
                </span>
                <span @click="addOptions">{{ $t('fieldManagement.addOption') }}</span>
              </div>
            </div>
            <div class="options" v-show="show === 5">
              <!-- <div class="optionsText"><span class="optionsTab">*</span>{{ $t('fieldManagement.optionsText') }}</div> -->
              <div class="select_header">
                <div class="s_h_name">{{ $t('fieldManagement.optionsName') }}</div>
                <div class="s_h_value">{{ $t('fieldManagement.optionsValue') }}</div>
                <div class="s_h_defalut">{{ $t('fieldManagement.optionsIsDefault') }}</div>
              </div>
              <div class="select_info" id="radioOption2">
                <option-set v-for="(item, index) in radioOptions" :key="index" :index="index + 1" :index2="index"
                  selectType="单选" @delClick="delClick" :content="item" :radioOptions="radioOptions"
                  @checkedChange="() => { selectCheckedChange(index) }"
                  @resortOptionSet="resortOptionSet('radioOption2')" @mouseUpClick="mouseUpClick">
                </option-set>
              </div>
              <div class="select_add">
                <span class="add_btn" @click="addOptions">
                  <i class="el-icon-plus"></i>
                </span>
                <span @click="addOptions">{{ $t('fieldManagement.addOption') }}</span>
              </div>
            </div>
            <div class="options" v-show="show === 6">
              <!-- <div class="optionsText"><span class="optionsTab">*</span>{{ $t('fieldManagement.optionsText') }}</div> -->
              <div class="select_header">
                <div class="s_h_name">{{ $t('fieldManagement.optionsName') }}</div>
                <div class="s_h_value">{{ $t('fieldManagement.optionsValue') }}</div>
                <div class="s_h_defalut">{{ $t('fieldManagement.optionsIsDefault') }}</div>
              </div>
              <div class="select_info" id="radioOption3">
                <option-set v-for="(item, index) in radioOptions" :key="index" :index="index + 1" :index2="index"
                  @delClick="delClick" :content="item" :radioOptions="radioOptions" @radioChange="radioChange"
                  selectType="多选" @resortOptionSet="resortOptionSet('radioOption3')" @mouseUpClick="mouseUpClick">
                </option-set>
              </div>
              <div class="select_add">
                <span class="add_btn" @click="addOptions">
                  <i class="el-icon-plus"></i>
                </span>
                <span @click="addOptions">{{ $t('fieldManagement.addOption') }}</span>
              </div>
            </div>
            <div class="cascadeOption" v-if="show === 7">
              <p class="options_tips">选择方式</p>
              <div class="options_type">
                <el-radio-group v-model="fieldType.selectType" @change="changeSelectType">
                  <el-radio label="单选">单选</el-radio>
                  <el-radio label="多选">多选</el-radio>
                </el-radio-group>
              </div>
              <div class="select_header">
                <div class="s_h_name">{{ $t('fieldManagement.optionsName') }}</div>
                <div class="s_h_value">{{ $t('fieldManagement.optionsValue') }}</div>
                <div class="s_h_defalut">{{ $t('fieldManagement.optionsIsDefault') }}</div>
              </div>
              <div class="select_info" id="radioOption4">
                <cascadeOption v-for="(option, index) in optionValue" :key="index" :option="option"
                  :selectType="fieldType.selectType"
                  @addNode="(type, borIndex, threeIndex) => { addOptionNode(type, borIndex, threeIndex, index) }"
                  @delClick="(pIndex, pThreeIndex) => { delOptionNode(index, pIndex, pThreeIndex) }"
                  @checkedChange="checkedChange" @resortOptionSet="resortOptionSet('radioOption4')"
                  @mouseUpClick="mouseUpClick">
                </cascadeOption>
              </div>
              <div class="select_add">
                <span class="add_btn" @click="addCascadeOption">
                  <i class="el-icon-plus"></i>
                </span>
                <span @click="addCascadeOption">{{ $t('fieldManagement.addOption') }}</span>
              </div>
            </div>
            <div v-show="show === 'EXPLANATION'">
              <div class="fileClass">
                <div style="margin: 0 10px">
                  {{ $t('fieldManagement.explanation') }}
                </div>
                <img
                  :src="$i18n.locale === 'en' ? require('@/assets/images/en_example.png') : require('@/assets/images/u2434.png')"
                  class="fieldImg" />
              </div>
              <div>
                <div style="margin-right: 12px;text-align: left">{{ $t('fieldManagement.explan') }}:</div>
                <div>
                  <el-input type="textarea" :autosize="{ minRows: 2, maxRows: 4 }" v-model="descriptionText"
                    :placeholder="$t('fieldManagement.explanationPlaceholder')"></el-input>
                </div>
              </div>
            </div>
          </el-form-item>
          <el-form-item label="是否备注" v-if="fieldType.value !== 'EXPLANATION'">
            <div slot="label" class="form-label">
              <div class="label-left">
                <span style="margin-right: 4px">{{ $t('fieldManagement.remarks') }}</span>
                <el-switch v-model="remark.enable" active-color="#366aff">
                </el-switch>
              </div>
              <span class="label-right">
                {{ $t('fieldManagement.remarksTip') }}
              </span>
            </div>
            <el-input v-model="remark.text" :placeholder="$t('fieldManagement.textPlaceholder')"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div slot="dialog-footer">
        <el-button @click="dialogFormVisible = false">{{ $t('common.cancel') }}</el-button>
        <el-button type="primary" @click="addFormField">{{ $t('common.save') }}</el-button>
      </div>
    </popup>

    <el-dialog :title="$t('fieldManagement.deleteChild')" :visible.sync="dialogFormVisibleDelete2"
      :close-on-click-modal="false" custom-class="deleteField" width="500px">
      <div class="fieldDialogDelText" style="text-align: left">{{ $t('fieldManagement.deleteChildTip') }}</div>

      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisibleDelete2 = false">{{ $t('common.cancel') }}</el-button>
        <el-button type="danger" @click="deleteAllOption">{{ $t('common.deleteText') }}</el-button>
      </div>
    </el-dialog>
    <el-dialog :title="$t('fieldManagement.deleteChild')" :close-on-click-modal="false"
      :visible.sync="dialogFormVisibleDelete" custom-class="deleteField" width="500px">
      <div class="fieldDialogDelText" style="text-align: left">{{ $t('fieldManagement.deleteChildTip') }}</div>

      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisibleDelete = false">{{ $t('common.cancel') }}</el-button>
        <el-button type="danger" @click="deleteOption()">{{ $t('common.deleteText') }}</el-button>
      </div>
    </el-dialog>

    <!-- 版本功能不可用提示 -->
    <popup v-if="versionTipDialog" @closeEvent="versionTipDialog = false">
      <div slot="popup-name" class="popup-name">无法访问</div>
      <div slot="popup-tip">您当前的版本无法使用该功能</div>
      <div slot="popup-con">
        <VersionTip tipIndex="4" />
      </div>
      <div slot="dialog-footer">
        <el-button size="small" plain @click="versionTipDialog = false">我知道了</el-button>
        <el-button type="primary" size="small" v-clipboard:copy="'4001-789-800'" v-clipboard:success="onCopy"
          v-clipboard:error="onError">拨打 4001-789-800 升级</el-button>
      </div>
    </popup>
  </div>
</template>
<script>
import OptionSet from "./optionset/OptionSet";
import Sortable from "sortablejs";
import FieldManageItem from './fieldManageItem'
import pageTopHeader from '../../components/page-top-header.vue'
import VersionTip from "./../version/PversionTip.vue";
import Pagination from "../fissionMarketing/components/pagination";
import Popup from '../../components/popup.vue';
import cascadeOption from "./optionset/cascadeOption.vue";
import { v4 as uuidv4 } from "uuid";
export default {
  data () {
    return {
      botVersion: 0,
      descriptionText: "", // 解释说明文案
      dialogFormVisible: false,
      dialogFormVisible1: false,
      dialogFormVisibleDelete2: false,
      dialogFormVisibleDelete: false,
      activePageInfo: {
        headerName: "接口对接"
      },
      labelPosition: 'top',
      form: {
        name: '',
        region: '',
        date1: '',
        date2: '',
        delivery: false,
        type: [],
        resource: '',
        desc: '',
      },
      formLabelWidth: '120px',
      rules: {
        name: [
          { required: true, message: '请输入字段名称', trigger: 'blur' }
        ],
      },
      currentPath: '',
      fieldList: [
      ],
      show: 0,
      fieldType: {
        value: 'INPUT',
        label: '单行文本',
        selectType: '单选'
      },
      fieldTypes: [
        {
          label: '单行文本',
          value: 'INPUT'
        },
        {
          label: '多行文本',
          value: 'TEXTAREA'
        },
        {

          label: '下拉列表',
          value: 'SELECT'
        },
        {

          label: '级联选择',
          value: 'CASCADER'
        },
        {

          label: '单选框',
          value: 'RADIO'
        },
        {

          label: '复选框',
          value: 'CHECKBOX'
        },
        {

          label: '日期选择',
          value: 'DATE_PICKER'
        },

        {

          label: '时间选择',
          value: 'TIME_PICKER'
        },
        {
          label: '附件',
          value: 'FILE'
        },
        {
          label: '解释说明',
          value: 'EXPLANATION'
        },
        {
          label: '资产',
          value: 'OPTION'
        }
        /* {
            label: '日期范围',
            value: 'DATE_RANGE'
          },
          {
            label: '时间范围',
            value: 'TIME_RANGE'
          },
          {
            label: '日期和时间范围',
            value: 'DATETIME_RANGE'
          }*/
      ],
      selectProvince: '',
      selectCity: '',
      checkSelect: ['选项1', '选项2'],
      radios: '1',
      select2: '',
      time1: '12:40:15',
      datePicker: '2020-08-02',
      singleText: '',
      MuliteText: '',
      fieldName: '',
      fieldDescription: '',
      dateTimeValue: '',
      dateValue: [new Date(2020, 9, 10, 8, 40), new Date(2020, 10, 10, 9, 40)],
      timeValue: [new Date(2020, 9, 10, 10, 40), new Date(2020, 9, 10, 11, 40)],
      radioOptions: [{ value: '', label: '', checked: false }, { value: '', label: '', checked: false }],
      cascadeOptionSelect: [],
      showInput1: 0,
      showInput2: 0,
      showInput3: 0,
      optionValue: [],
      cascadeOptionSelect1: '',
      cascadeOptionSelect2: '',
      cascadeOptionSelect3: '',
      addCascade: 1,
      addCascade2: 1,
      addCascade1: 1,
      selectOption1: [],
      optionName: '',
      optionName2: '',
      optionName3: "",
      cascadeOptionChildren: "",
      OptionChildren: [],
      OptionChildren2: [],
      cascadeOptionChildren2: '',
      fristOption: 1,
      secondOption: 1,
      thirdOption: 1,
      fristName: '',
      secondName: '',
      thirdName: '',
      num2: 0,
      deleteOption1: 1,
      deleteOption2: 1,
      deleteOption2Index: '',
      deleteOptionIndex: '',
      rivese1: '',
      rivese2: '',
      rivese3: '',
      showOption1: '',
      showOption2: '',
      showOption3: '',
      riveseName1: '',
      riveseName2: '',
      riveseName3: '',
      riveseIndex1: '',
      riveseIndex2: '',
      riveseIndex3: '',
      radioOptionsTest: [],
      cascadeOptionSelect1Num: 0,
      cascadeOptionSelect2Num: 0,
      cascadeOptionSelect3Num: 0,
      fieldPage: 1,
      fieldCurrentSize: 20,
      fieldTotal: 0,
      fileTypeList: [
        { label: '图片', value: 'PICTURE' },
        { label: '视频', value: 'VIDEO' },
        { label: '音频', value: 'AUDIO' },
      ],
      fileTypeCheck: ['PICTURE'],  //允许上传的文件类型
      fileLimit: 'one',
      limitNum: 1,
      remark: {
        enable: false,
        text: ""
      },  //字段是否备注

    }
  },
  components: {
    Pagination,
    OptionSet,
    FieldManageItem,
    pageTopHeader,
    VersionTip,
    Popup,
    cascadeOption
  },
  mounted () {
    this.getCurrentUrl();
    this.getField()
    this.botVersion = localStorage.getItem('_bot_version');
  },
  computed: {
    isShow () {
      let mainId = localStorage.getItem('_mainId')
      return mainId == '90df4764122240de939331d372546c28' || mainId == '1419b09cb2ce11ea8f0900163e0964d5'
    }
  },
  methods: {
    // Copy成功
    onCopy (e) {
      this.$message.success(this.$t('common.copySuccessText'));
    },
    // Copy失败
    onError (e) {
      this.$message.error(this.$t('common.copyFailText'));
    },
    limitWebhook () {
      this.versionTipDialog = true;
    },
    handleSizeChange (value) {
      this.fieldCurrentSize = value
      this.getField()
    },
    handleCurrentChange (value) {
      this.fieldPage = value
      this.getField()
    },
    getCurrentUrl () {
      this.currentPath = this.$route.path;
      console.log('path:', this.currentPath);
    },
    //点击新增字段的按钮事件
    addFieldClick () {
      this.fieldName = ''
      this.fieldDescription = ''
      this.fieldType.value = 'INPUT'
      this.show = 0
      this.dialogFormVisible = true;
      this.remark.enable = false;
      this.remark.text = "";
    },
    //获取字段
    getField () {
      this.FetchGet('/api/form/fields?page=' + this.fieldPage + '&size=' + this.fieldCurrentSize).then(res => {
        this.fieldTotal = res.data.total
        this.fieldList = res.data.list
      })
    },
    //新增字段
    addFormField () {
      if (this.fieldName === '') {
        this.$message({
          message: this.$t('fieldManagement.fieldNameNull'),
          type: 'error',
          duration: 1000
        })
        return
      }
      /* if(this.fileTypeCheck.length === 0)
        {
            this.$message({
                message:'请至少选择一种文件类型',
                type:'error',
                duration:2000
            })
            return
      }*/
      if (this.fileLimit === 'more' && this.limitNum === '') {
        this.$message({
          message: this.$t('fieldManagement.maxUploadNumber'),
          type: 'error',
          duration: 2000
        })
        return
      }
      else {
        let extInfo
        let radioOptionsList = []
        if (this.fieldType.value === 'RADIO' || this.fieldType.value === 'CHECKBOX' || this.fieldType.value === 'SELECT') {
          this.radioOptions.forEach(item => {
            if (item.label !== '') {
              radioOptionsList.push(item)
            }
          })
          extInfo = {
            option: radioOptionsList.map(opt => {
              if(opt.checked) {
                opt._default = 'true'
              } else {
                opt._default = 'false'
              }
              delete opt.checked
              return opt
            }),
            selectType: this.fieldType.selectType,
            value: [],
            WebHookParameter: [],
            remark: this.remark
          }
          if (radioOptionsList.length === 0) {
            this.$message({
              message: this.$t('fieldManagement.minOption'),
              type: 'error',
              duration: 2000
            })
            return;
          }
        } else if (this.fieldType.value === 'OPTION') {
          extInfo = {
            option: [],
            value: [],
            WebHookParameter: [],
            remark: this.remark
          }
        }
        else if (this.fieldType.value === 'CASCADER') {
          let fieldValue = this.setDefalutValue(this.fieldType.selectType)
          extInfo = {
            cascade: this.optionValue,
            value: [],
            WebHookParameter: [],
            remark: this.remark,
            selectType: this.fieldType.selectType,
            fieldValue: fieldValue
          }
        }
        else if (this.fieldType.value === 'FILE') {
          extInfo = {
            fileLimit: this.fileLimit,
            WebHookParameter: [],
            remark: this.remark
          }
          if (this.fileLimit === 'more') {
            extInfo.limitNum = this.limitNum
          }
        }
        else if (this.fieldType.value === 'EXPLANATION') {
          extInfo = {
            descriptionText: ""
          }
          if (this.descriptionText.trim() === '') {
            this.$message({
              message: this.$t('fieldManagement.explanationNull'),
              type: 'error',
              duration: 2000
            })
            return;
          } else {
            extInfo.descriptionText = this.descriptionText.trim();
          }
        }
        else {
          extInfo = {
            value: '',
            WebHookParameter: [],
            remark: this.remark
          }
        }
        let field = {
          description: this.fieldDescription,
          extInfo: extInfo,
          name: this.fieldName,
          type: this.fieldType.value
        }
        console.log(field)

        this.FetchPost('/api/form/field', field).then(res => {
          console.log(res)
          if (res.code === '0') {
            this.$message.success(this.$t('fieldManagement.addFieldSuccess'))
            this.dialogFormVisible = false
            this.getField()
          }
        })
      }
    },
    setDefalutValue (selectType) {
      let fieldValue = []
      // 这里懒得改递归了，固定三层结构，就循环一下吧
      this.optionValue.forEach(item => {
        delete item.key
        if (item.checked) {
          if (selectType == '单选') {
            fieldValue = [item.value]
          } else {
            fieldValue.push([item.value])
          }
        }
        if (item.children !== undefined) {
          if (item.children.length === 0) {
            delete item.children
          } else {
            item.children.forEach(items => {
              delete items.key
              if (items.checked) {
                if (selectType == '单选') {
                  fieldValue = [item.value, items.value]
                } else {
                  fieldValue.push([item.value, items.value])
                }
              }
              if (items.children !== undefined) {
                if (items.children.length === 0) {
                  delete items.children
                } else {
                  items.children.forEach(it => {
                    delete it.key
                    if (it.checked) {
                      if (selectType == '单选') {
                        fieldValue = [item.value, items.value, it.value]
                      } else {
                        fieldValue.push([item.value, items.value, it.value])
                      }
                    }
                  })
                }
              }
            })
          }
        }
      })
      return fieldValue
    },
    modifyField () {
      this.getField()
    },
    deleteField () {
      this.getField()
    },
    reviseField () {
      this.getField()
    },
    //新增字段选择下拉框
    selectType (value) {
      if (value === 'INPUT') {
        this.show = 0
      }
      if (value === 'TEXTAREA') {
        this.show = 1
      }
      if (value === 'DATE_PICKER') {
        this.show = 2
      }
      if (value === 'TIME_PICKER') {
        this.show = 3

      }
      if (value === 'SELECT') {
        this.show = 4;
        this.fieldType.selectType = '单选'
      }
      if (value === 'RADIO') {
        this.show = 5
      }
      if (value === 'CHECKBOX') {
        this.show = 6
      }
      if (value === 'CASCADER') {
        this.show = 7
        this.fieldType.selectType = '单选'
      }
      if (value === 'FILE') {
        this.show = 'FILE'
      }
      if (value === 'EXPLANATION') {
        this.show = 'EXPLANATION'
      }
      if (value === 'OPTION') {
        this.show = value;
      }
      this.$forceUpdate()
      this.stepBar = 0;
      this.active = 1;
      this.fieldType.value = value;
      this.fieldType.label = value;
      this.radioOptions = [{ value: '', label: '', checked: false }, { value: '', label: '', checked: false }]
      this.$set(this, 'optionValue', [{
        value: '',
        label: '',
        key: uuidv4(),
        checked: false,
        children: []
      }])
    },
    //编辑字段
    delClick (index) {
      this.radioOptions.splice(index - 1, 1);
    },
    selectCheckedChange (index) {
      if (this.fieldType.selectType == '单选') {
        this.radioOptions.forEach((item, i) => {
          item.checked = index == i
        })
      }
    },
    resortOptionSet (radioOptionEl) {
      let _this = this;
      let el = document.getElementById(radioOptionEl);
      el && new Sortable(el, {
        ghostClass: 'blue-background-class',
        animation: 200,
        sort: true,
        handle: '.handles',
        onUpdate: function (event) {
          //修改items数据顺序
          let newIndex = event.newIndex,
            oldIndex = event.oldIndex,
            $li = el.children[newIndex],
            $oldLi = el.children[oldIndex];
          // 先删除移动的节点
          el.removeChild($li)
          // 再插入移动的节点到原有节点，还原了移动的操作
          if (newIndex > oldIndex) {
            el.insertBefore($li, $oldLi)
          } else {
            el.insertBefore($li, $oldLi.nextSibling)
          }
          // 更新items数组
          if (radioOptionEl == 'radioOption4') {
            let item = _this.optionValue.splice(oldIndex, 1)
            _this.optionValue.splice(newIndex, 0, item[0])
          } else {
            let item = _this.radioOptions.splice(oldIndex, 1)
            _this.radioOptions.splice(newIndex, 0, item[0])
          }
        },
      });
    },
    mouseUpClick () { },
    //添加选项
    addOptions () {
      this.radioOptions.push({ value: '', label: '', checked: false });
    },
    changeSelectType () {
      this.radioOptions.forEach(item => {
        item.checked = false
      })
      this.loopChangeCheck(this.optionValue, '')
      this.$forceUpdate()
    },
    addCascadeOption () {
      this.$set(this.optionValue, this.optionValue.length, {
        label: '',
        value: '',
        key: uuidv4(),
        checked: false,
        children: []
      })
      console.log(this.optionValue);
    },
    addOptionNode (type, borIndex, threeIndex, index) {
      if (borIndex === undefined && threeIndex === undefined) {
        // 添加子级时取消当前的默认选中状态
        this.$set(this.optionValue[index], 'checked', false)
        if(!this.optionValue[index].children) {
          this.$set(this.optionValue[index], 'children', [])
        }
        let count = this.optionValue[index].children.length ? this.optionValue[index].children.length : 0
        this.$set(this.optionValue[index].children, count, {
          label: '',
          value: '',
          checked: false,
          key: uuidv4(),
          children: []
        })
        return
      }
      if (borIndex !== undefined && threeIndex === undefined) {
        if (type == "brother") {
          if(!this.optionValue[index].children) {
            this.$set(this.optionValue[index], 'children', [])
          }
          let count = this.optionValue[index].children.length ? this.optionValue[index].children.length : 0
          this.$set(this.optionValue[index].children, count, {
            label: '',
            value: '',
            checked: false,
            key: uuidv4(),
            children: []
          })
        } else {
          if(!this.optionValue[index].children[borIndex].children) {
            this.$set(this.optionValue[index].children[borIndex], 'children', [])
          }
          // 添加子级时取消当前的默认选中状态
          this.$set(this.optionValue[index].children[borIndex], 'checked', false)
          let count = this.optionValue[index].children[borIndex].children.length
          this.$set(this.optionValue[index].children[borIndex].children, count, {
            label: '',
            value: '',
            checked: false,
            key: uuidv4(),
          })
        }
        return
      }
      if (threeIndex !== undefined) {
        if(!this.optionValue[index].children[borIndex].children) {
          this.$set(this.optionValue[index].children[borIndex], 'children', [])
        }
        let count = this.optionValue[index].children[borIndex].children.length
        this.$set(this.optionValue[index].children[borIndex].children, count, {
          label: '',
          value: '',
          checked: false,
          key: uuidv4(),
        })
      }
    },
    checkedChange (key) {
      this.loopChangeCheck(this.optionValue, key)
    },
    loopChangeCheck (list, key) {
      if (list) {
        for (let index = 0; index < list.length; index++) {
          const item = list[index];
          if (item.key == key) {
            item.checked = true
          } else {
            item.checked = false
          }
          this.loopChangeCheck(item.children, key)
        }
      }

    },
    delOptionNode (index, pIndex, pThreeIndex) {
      if (pIndex === undefined && pThreeIndex === undefined) {
        this.optionValue.splice(index, 1)
        return
      }
      if (pIndex !== undefined && pThreeIndex === undefined) {
        this.optionValue[index].children.splice(pIndex, 1)
        return
      }
      if (pIndex !== undefined && pThreeIndex !== undefined) {
        this.optionValue[index].children[pIndex].children.splice(pIndex, 1)
        return
      }
    },
    deleteAllOption () {
      if (this.fristOption === 0) {
        this.optionValue = []
        this.OptionChildren2 = []
        this.OptionChildren = []
        this.fristOption = 1
        this.cascadeOptionSelect1 = ''
        this.fristOption = 1
        this.addCascade2 = 0
        this.addCascade = 0

      }
      if (this.secondOption === 0) {
        this.OptionChildren = []
        this.OptionChildren2 = []
        this.optionValue.forEach(item => {
          item.children = []
          this.secondOption = 1
          this.cascadeOptionSelect2 = ''
          this.addCascade2 = 0
          this.addCascade = 0
        })

      }
      this.dialogFormVisibleDelete2 = false
    },
    deleteOption () {
      if (this.deleteOption1 === 2) {
        console.log(this.optionValue)
        console.log(this.fristName)
        this.optionValue.forEach(item => {
          if (item.value === this.fristName) {
            this.optionValue.splice(this.deleteOptionIndex, 1)
            this.OptionChildren = []
            this.OptionChildren2 = []
            this.cascadeOptionSelect1 = ''
            this.cascadeOptionSelect2 = ''
            this.cascadeOptionSelect3 = ''
          }

        })
        this.deleteOption1 = 0


        console.log(this.optionValue)
        console.log(this.OptionChildren)
      }
      if (this.deleteOption2 === 2) {
        this.optionValue.forEach(item => {
          console.log(this.cascadeOptionSelect1)
          if (item.value === this.cascadeOptionSelect1) {
            item.children.forEach(items => {
              items.children.splice(this.deleteOption2Index, 1)
              this.OptionChildren.splice(this.deleteOption2Index, 1)
              this.OptionChildren2 = []
              this.cascadeOptionSelect2 = ''
              this.cascadeOptionSelect3 = ''
            })
          }
        })

        if (this.cascadeOptionSelect2 === this.secondName) {
          this.cascadeOptionSelect3 = ''
        }

        this.deleteOption2 = 0
      }
      this.dialogFormVisibleDelete = false
    }
  }
}
</script>
<style  lang="less">
@import "./../../assets/less/formManage/fieldManage.less";

.disa-webhook {
  line-height: 60px;
  height: 60px;
  font-size: 18px;
  color: #303133;
  margin-left: 14px;
  opacity: 0.6;
  cursor: pointer;

  span {
    display: inline-block;
    font-size: 14px;
    margin-left: 8px;
    margin-top: 12px;
  }
}

.blue-background-class {}

.el-menu-item-group__title {
  padding: 0 !important;
}

.el-submenu .el-menu-item {
  height: 48px;
  line-height: 48px;
  width: 150px;
  padding: 0 45px;
  min-width: 150px;
}

.activeClass {
  border-right: 2px solid #366AFF;
}

.el-form-item__label {
  line-height: 1;
}

// .el-submenu__title{
//     padding: 0 10px!important;
// }
.el-pagination {
  margin: 0;
}

.fileClass {
  /deep/.el-upload-list {
    display: none;
  }

  .upload-demo {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 56px;
  }

  .upload--picture-card {
    background-color: #fbfdff;
    border: 1px dashed #c0ccda;
    border-radius: 6px;
    width: 40px;
    height: 40px;
    line-height: 56px;
    vertical-align: top;
    cursor: pointer;
    text-align: center;
    font-size: 28px;
    color: #8c939d;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>

<style lang="less" scoped>
.dialog_form {
  .options_tips {}

  .options_type {
    width: 100%;
    padding: 0 12px;
    background: #F6F8FD;
    box-sizing: border-box;
    border-radius: 5px;
  }

  .select_header {
    width: 100%;
    height: 32px;
    display: flex;
    align-items: center;
    margin-top: 10px;

    .s_h_name {
      width: 232px;
      padding-left: 5px;
      line-height: 32px;
      box-sizing: border-box;
    }

    .s_h_value {
      width: 232px;
      padding-left: 5px;
      line-height: 32px;
      box-sizing: border-box;
    }

    .s_h_defalut {
      width: 60px;
      line-height: 32px;
    }
  }

  .select_info {
    width: 100%;
    // background: #F6F8FD;
    // box-sizing: border-box;
    border-radius: 5px;
    padding: 5px 0;
  }

  .select_add {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: #366AFF;

    .add_btn {
      width: 28px;
      height: 28px;
      background: #366AFF;
      border-radius: 5px;
      color: #ffffff;
      font-size: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 8px;
      cursor: pointer;
    }

    span {
      cursor: pointer;
    }

  }

  /deep/.el-form-item {
    width: 100%;

    .el-form-item__label {
      width: 100%;
    }
  }

  /deep/.form-label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    .label-left {
      display: flex;
      align-items: center;
      width: 100px;
      flex: none;
    }

    .label-right {
      color: #C0C4CC;
    }
  }
}
</style>